/* ==========================================================================
 * Rover's modal, based on bootstrap's modal
 * ========================================================================== */

@import '../utilities/mixins';
@import '../utilities/_variables';
@import '../vendor/bootstrap/scss/mixins';
@import '../vendor/bootstrap/scss/modals';

.rover-modal {
  @extend .modal;

  .modal-content {
    @include responsive-spacing('padding', 6);
    border: none;
    border-radius: 0;
  }

  .modal-header {
    padding: 0px;
    @include responsive-spacing('padding-bottom', 4);
    border-bottom: 0px;

    .close {
      margin-top: 4px;
      font-size: 1.8em;
      color: $text-color-tertiary;

      &:hover {
        color: $text-color-secondary;
      }
    }
  }

  .modal-footer {
    border-top: 0px;
    padding: 0px;
    @include responsive-spacing('padding-top', 4);
  }

  .modal-body {
    // Modals need to allow for popovers to be shown outside the modal container
    // This is common for form validation inside a modal
    overflow: visible;
    padding: 0px;
  }

  .modal-dialog {
    @include not-xs {
      // Modals need to clear the admin toolbar
      margin-top: 100px;
    }

    &.modal-center {
      margin-top: 30px;
      top: 50%;
      @include translate3d(0, -50%, 0);
    }
  }

  &.fade .modal-dialog.modal-center {
    @include translate3d(0, -100%, 0);
    @include transition-transform(0.3s ease-out);
  }

  &.in .modal-dialog.modal-center {
    @include translate3d(0, -50%, 0)
  }

  .full-bleed {
    margin-left: -$modal-inner-padding;
    margin-right: -$modal-inner-padding;
  }

  // Fullscreen mode for small-screen
  &.rover-modal-fullscreen {
    @include xs-only {
      background: white;

      &.rover-loading {
        position: fixed;

        &:before,
        &:after {
          position: fixed;
        }
      }

      // Maximize the main wrappers on the screen
      .modal-dialog,
      .modal-content {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }

      // Make the parent wrapper of the modal box a full-width block
      .modal-dialog {
        margin: 0;
        width: auto;
      }

      /* Remove borders and effects on the content */
      .modal-content {
        border: none;
        @include border-radius(0);
        @include box-shadow(inherit);
      }
    }
  }

  // Chromeless  modal for displaying media
  &.rover-media-modal {
    $content-padding: 20px;
    $close-button-space: 22px;
    // We want more vertical padding on larger screens
    // to increase the clickable 'close' area
    $vertical-padding-small-up: 80px;

    // Maximize the main wrappers on the screen
    .modal-dialog,
    .modal-content,
    .modal-body,
    .modal-inner-body {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
    }

    .modal-dialog {
      width: auto;
      margin: 0 auto;
      bottom: $content-padding;
      top: $content-padding + $close-button-space;

      @include sm-up {
        bottom: $vertical-padding-small-up;
        top: $vertical-padding-small-up + $close-button-space;
      }
    }

    .close {
      position: absolute;
      top: -$close-button-space;
      right: calc($content-padding/2);
      opacity: 0.9;
      color: white;
      text-shadow: 0 1px 0 black;

      @include sm-up {
        top: -1 * ($vertical-padding-small-up);
      }
    }

    .modal-content {
      border: none;
      background: transparent;
      box-shadow: none;
    }
  }
}

.rover-modal-mobile-footer,
.rover-modal-mobile-header {
  // The following is from bootstrap's _modals.scss
  padding: $modal-inner-padding;
  text-align: right; // right align buttons
  @include clearfix(); // clear it in case folks use .pull-* classes on buttons

  // Properly space out buttons
  .btn+.btn {
    @include responsive-spacing('margin-left', 3);
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
  }

  // but override that for button groups
  .btn-group .btn+.btn {
    margin-left: -1px;
  }

  // and override it for block buttons as well
  .btn-block+.btn-block {
    margin-left: 0;
  }

  @include not-xs {
    display: none;
  }

  @include xs-only {
    &.fixed {
      width: 100%;
      position: fixed;
      background: white;
      z-index: 5000;
    }
  }
}

.rover-modal-mobile-footer {
  border-top: 1px solid $modal-footer-border-color;

  @include xs-only {
    &.fixed {
      bottom: 0;
    }
  }
}

.modal-align-bottom {
  position: fixed !important;
  top: auto !important;
  bottom: 0px;
}

.phone-detection-mobile-modal .modal-dialog {
  margin: 0;
}

.phone-detection-mobile-modal .modal-content {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.phone-detection-modal[aria-hidden="false"] {

  // Make sure the modal is not sticky on mobile.
  @include not-xs {
    /* 
        *  To avoid setting `fixed` to all modals, 
        *  we need to use !important to override inline styles specificity rules.
        */
    position: fixed !important;
  }
}

.phone-detection-modal .modal-content {
  border-radius: 4px;
}

.rover-modal-mobile-header {
  border-bottom: 1px solid $modal-footer-border-color;

  @include xs-only {
    margin-left: -10px; // TODO: figure out why header is shifted 10px right by default

    &.fixed {
      top: 0;
    }
  }
}

.modal-blocking-modal-auto-away-sitters {
  .container-fluid {
    padding: 0px;
  }

  .auto-away-icons-col {
    padding: 0px;
  }

  .auto-away-icons-box {
    width: 70px;
    height: 70px;
  }

  i.rover-icon-in-circle-filled {
    color: #e7f5f8;
    z-index: 1;
  }

  i.rover-icon-unavailable {
    color: $secondary-2;
    z-index: 2;
    position: relative;
    top: -61px;
  }

  @include not-xs {
    .auto-away-blocking-modal-buttons-col {
      text-align: right;
    }

    .stay-away-mode-button {
      margin-right: 15px;
    }
  }

  @include xs-only {
    height: 97%;

    .modal-content,
    .modal-inner-body,
    .modal-body {
      height: 100%;
    }

    .modal-content {
      padding: 20px 10px;
    }

    h3 {
      margin: 20px 0px;
    }

    .auto-away-icons-box {
      margin-left: auto;
      margin-right: auto;
    }

    .blocking-modal-form-row {
      position: absolute;
      bottom: 0px;
      margin: 0px;
      width: 100%;
    }

    .auto-away-blocking-modal-buttons-col {
      text-align: center;
      padding: 0px;
    }

    .stay-away-mode-button {
      margin-top: 30px;
    }

    .update-services-stay-away-mode-button {
      margin-top: 50px;
      width: 100%;
    }
  }
}

.modal-blocking-modal-marketing-opt-in-experiment {
  width: 100%;
  height: 100%;
  margin: 0 !important;

  .modal-content {
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 32px;
    width: 600px;
    border-radius: 4px;
  }

  .container-fluid {
    padding: 0px;
  }

  .container-fluid:first-child {
    margin-bottom: 28px;
  }

  .modal-header {
    padding: 0px;
    margin-bottom: 24px;
  }

  h4 {
    margin: 0px;
    padding-bottom: 24px;
  }

  .auto-away-blocking-modal-buttons-col {
    text-align: right;
    padding: 0px;
  }

  .blocking-modal-form-row {
    margin: 0px;
  }

  .close-button {
    position: fixed;
    right: 32px;
    top: 32px;
    padding: 0px;
    color: transparent;
    background-color: transparent;
    height: 24px;
    line-height: normal;
  }

  .marketing-opt-in-buttons:focus {
    outline: none;
  }

  .marketing-opt-in-buttons:focus-visible {
    outline: 2px solid #2E67D1;
  }
}

.modal-blocking-modal-e-delivery-consent-1099 {
  @media (max-width: $screen-sm-max) {
    .close-button {
      position: fixed !important;
      right: 32px !important;
      top: 25px !important;
    }

    .modal-title {
      margin-right: 10px;
    }
  }

  .blocking-modal-form-row {
    margin-top: 0px !important;
    flex-direction: column;
  }
}

.modal-blocking-modal-email-verification-blocking-modal {
  .modal-title {
    @extend .sr-only;
  }

  .modal-content {
    border-radius: 8px;
  }
}

.modal-blocking-modal-community-guidelines-modal {
  max-height: 100vh;
  overflow: auto;

  @include xs-only {
    position: fixed !important;
    bottom: 0px;
    top: auto !important;
    margin: 0;

    .modal-content {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
  }

  @include not-xs {
    .modal-content {
      border-radius: 8px;
    }
  }

  h2,
  h3 {
    font-family: $font-family-serif;
    font-size: $font-size-h2;
    font-weight: 600;
    line-height: 1.25;
    margin-top: 0px;
    margin-bottom: 16px;
  }

  p {
    font-size: $font-size-large;
  }

  a {
    text-decoration: none;
  }

  button {
    padding: 12px 24px;
  }

  .modal-content {
    padding: 24px;
  }
}