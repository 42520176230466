@import '../utilities/variables';
@import '../utilities/mixins';
@import 'button_groups';

.header-toolbar-wrapper {
  background-color: rgba(40, 40, 40, 1) !important;
  width: 100%;
  position: relative;
  z-index: $zindex-modal-background - 10;
  padding: 5px;
  display: flex; 
  justify-content: space-between;

  .header-toolbar {
    overflow: hidden;
    color: white;
    width: 100%;
    .idv-required-text {
      font-weight: normal;
      float: left;
      font-size:16px;
      margin-left: 8px;
      color: #a43d3d;
    }
    .impersonate-user-text {
      font-weight: normal;
      float: left;
      font-size: 16px;
    }
    .toolbar-action-btn {
      margin: 6px 5px;
      float: right;
      color: white;
      font-size: 13px;
    }
  }
  @include xs-only {
    display: none;
  }
  #toolbar-more-dropdown {
    background: rgba(40, 40, 40, 1); 
    display: flex; 
    flex-direction: column; 
    gap: 10px; 
    border-radius: 5px; 
    padding: 1rem; 
    position: absolute; 
    right: 25px; 
    top: 56px;

    a {
      color: white;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline 
    }
  }
  #toolbar-more-dropdown:focus {
    outline: none
  }
}

.rover-btn.rover-btn-primary.become-a-sitter-cta {
  padding: 8px 16px;
  margin-top: 5px;
  color: white;

  &:hover,
  &:focus {
    background-color: $primary-2;
    color: white;
  }

  &.become-a-sitter-cta-mobile {
    color: $primary-1;
    background-color: #e8fcf7;
    border: none;
    float: right;

    &.become-a-sitter-cta-mobile-anonymous {
      margin-right: 20px;
    }
  }
}
