@import '../base/colors';
@import '../utilities/mixins';
@import '../utilities/flexbox';

.global-announcement-bar {
  background-color: $yellow-100;

  z-index: 1;
  padding-top: 16px;
  padding-bottom: 16px;

  @include not-xs {
    order: 1 !important;
  }

  .announcement-container {
    @include flexbox;
    font-size: 16px;
    line-height: 20px;
    z-index: 100;

    padding-left: 15px;
    padding-right: 15px;

    .rover-icon {
      line-height: inherit;
      cursor: pointer;
    }

    .announcements {
      @include flex-grow(1);
      padding: 0px;
    }

    @include md-up {
      text-align: center;
    }
  }

  a {
    color: $text-color-primary;
    text-decoration: underline;
    font-weight: 700;
  }

  &.sitewide-emergency-announcement {
    color: white;
    background-color: $danger !important;

    .rover-icon {
      color: white;
    }

    a {
      color: white;
    }
  }

  &.engineering-hiring {
    background-image: url('../../images/global_announcements/bg-banner-tech.png');
    background-repeat: no-repeat;
    background-position: top center;

    @include sm-only {
      background-size: auto;
    }
  }

  &.covid19 {
    background-color: $cyan-200 !important;
  }

  &.identity-verification-passed {
    background-color: #F1FDF6 !important;
    border: 2px solid #1A824E;
  }

  &.update-birthdate-required {
    background-color: $cyan-200 !important;
  }

  &.soft-launch-announcement {
    background-color: $orange-300 !important;
  }

  &.day-care-centers {
    background-color: $green-100 !important;
  }

  .dismiss {
    font-size: 24px;
    color: $text-color-secondary;
    background-color: transparent;
    border-color: transparent;

    @include not-xs {
      display: flex;
      align-items: center;
    }
  }
}


.announcement-banner-improvement {
  background-color: $orange-300;
}