@import '../utilities/variables';

.app-banner {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;

  @media print {
    display: none;
  }

  @media only screen and (min-width: $screen-sm-min) {
    display: none;
  }

  .banner-wrapper {
    align-items: center;
    background-color: white;
    border-bottom: 1px solid #dadada;
    display: flex;
    flex-direction: row;
    padding: 10px 10px 10px 0px;
    width: 100%;

    .close-column {
      padding-left: 8px;
      padding-right: 8px;

      & > button {
        float: none;

        & > span {
          font-size: 16px;
        }
      }
    }

    .icon-column {
      padding-right: 5px;

      .icon {
        width: 50px;
        height: 50px;
        border-radius: 4px;
        overflow: hidden;
      }
    }

    .info-column {
      .name-column {
        line-height: 1;
        padding-bottom: 4px;
      }

      .review-stars {
        font-size: 12px;
        color: rgb(229, 178, 63);
        line-height: 1;
        & > i {
          font-size: 10px;
        }
        padding-bottom: 4px;
      }

      .review-column {
        line-height: 1;
        & > span {
          font-size: 12px;
        }
      }
    }

    .button-column {
      margin-left: auto;
    }
  }
}

body {
  padding-top: 0px;
  &.has-banner {
    padding-top: 70px;
  }
}
