/* ==========================================================================
 * Rover Datepicker Styles
 * ========================================================================== */

@import '../utilities/variables';

.ui-datepicker {
  box-shadow: 0 1px 0 2px rgba($gray-1, 0.2);
  border-color: $border-color;
  border-radius: 4px;

  // Calendar should be above everything until
  // we decide otherwise
  z-index: 9999 !important;

  .ui-datepicker-title {
    line-height: 2.2em;
  }

  .ui-datepicker-prev,
  .ui-datepicker-next {
    position: absolute;
    top: 4px;
    width: 2em;
    height: 2em;
    cursor: pointer;
    border: 1px solid transparent;
    color: $primary-1;

    &.ui-state-hover {
      border-color: none;
      background: none;
      color: $primary-2;
    }

    span {
      font-size: 0;
      text-indent: 0;
      background: none;

      &:before {
        font-family: 'rover-icons';
        display: block;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  // Draw fontawesome chevrons
  .ui-datepicker-next {
    right: 4px;

    span:before {
      content: '\e624';
      text-align: right;
    }
  }
  .ui-datepicker-prev {
    left: 4px;

    span:before {
      content: '\e623';
      text-align: left;
    }
  }

  th {
    text-align: center;
    font-weight: normal;
    padding-bottom: 0.1em;
    font-size: 13px;
    border-bottom: 1px solid $grey-light;
  }

  td {
    border: 0;
    padding: 1px;

    .ui-state-default {
      font-weight: normal;
      padding: 0.25em;
      text-align: center;
      text-decoration: none;
    }

    &.ui-state-highlight {
      border: none;

      a.ui-state-default,
      a.ui-state-default:hover {
        background: $primary-1;
        color: white;
      }
    }

    span.ui-state-default {
      color: $text-color;
    }

    a.ui-state-default {
      color: $primary-1;

      &:hover {
        background: $grey-light;
        color: $primary-1;
      }
    }
  }
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: none;
  background: #fff;
}

.ui-widget-header {
  border: none;
  background: #fff;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  color: darken(#0073ea, 20%);
  background-color: #eee;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  color: #5cb85c;
  background-color: #eee;
}

.ui-datepicker td span,
.ui-datepicker td a {
  text-align: center;
}
