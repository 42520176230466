/* ==========================================================================
 * Alerts
 * ========================================================================== */

@import '../utilities/mixins';

body.flush:not(.manual-alerts) .js-alerts-container {
  position: relative;

  @include sm-up {
    @include absolute-alert;
  }
}
.alert-primary {
  background: $white;
  border: 1px solid $gray-lighter;
  color: #333;
  padding: 0;

  .alert-content {
    border-left: 15px solid $secondary-1;
    padding: $alert-padding;
    border-radius: $alert-border-radius - 1;
  }

  &.quiz-alert .instructions {
    padding-top: 3px;
  }
}

.rover-alert-success {
  @include rover-alert-variant($brand-primary);
}

.rover-alert-info {
  @include rover-alert-variant($link-color);
}

.rover-alert-warn {
  @include rover-alert-variant($notice);
}

.rover-alert-danger {
  @include rover-alert-variant($danger);
}

.rover-alert-blocking {
  border: 1px solid rgba($secondary-1, 0.25);
  border-radius: 4px;
  padding: 15px 25px 15px 75px;
  position: relative;

  &__icon {
    position: absolute;
    left: 15px;
    width: 45px;
    height: 45px;
    top: 50%;
    transform: translateY(-50%);
    color: $tertiary-1;
    text-align: center;
    font-size: 35px;

    img {
      max-width: 100%;
    }
  }

  .notice {
    color: $notice !important;
  }

  &.no-border {
    border: 0;
  }

  p {
    font-weight: 700;
    margin: 0;
    padding: 0;

    &.normal {
      font-weight: normal;
    }

    a {
      font-weight: 400;
      text-decoration: underline;
    }
  }
}
