@font-face {
  font-family: 'Averta';
  src: url('../../shared/fonts/Averta-Regular-Basic.woff2') format('woff2'),
    url('../../shared/fonts/Averta-Regular-Basic.woff') format('woff');
  font-display: swap;
  font-weight: 400;
  unicode-range: U+A, U+D, U+20-7E, U+A9, U+D7, U+2010-201F, U+2026, U+2122, U+1F1F8, U+1F1FA; /* Basic Latin glyphs + some common extras */
}
@font-face {
  font-family: 'Averta';
  src: url('../../shared/fonts/Averta-Regular-Extended.woff2') format('woff2'),
    url('../../shared/fonts/Averta-Regular-Extended.woff') format('woff');
  font-display: swap;
  font-weight: 400;
  unicode-range: U+A1-A8, U+AA-D6, U+D8-2009, U+2027-2121, U+2123-1F1F7, U+1F1F9, U+1F1FB-E0000; /* Extended Glyphs: should almost never load in english locales */
}
@font-face {
  font-family: 'Averta';
  src: url('../../shared/fonts/Averta-Semibold-Basic.woff2') format('woff2'),
    url('../../shared/fonts/Averta-Semibold-Basic.woff') format('woff');
  font-display: swap;
  font-weight: 700;
  unicode-range: U+A, U+D, U+20-7E, U+A9, U+D7, U+2010-201F, U+2026, U+2122, U+1F1F8, U+1F1FA; /* Basic Latin glyphs + some common extras */
}
@font-face {
  font-family: 'Averta';
  src: url('../../shared/fonts/Averta-Semibold-Extended.woff2') format('woff2'),
    url('../../shared/fonts/Averta-Semibold-Extended.woff') format('woff');
  font-display: swap;
  font-weight: 700;
  unicode-range: U+A1-A8, U+AA-D6, U+D8-2009, U+2027-2121, U+2123-1F1F7, U+1F1F9, U+1F1FB-E0000; /* Extended Glyphs: should almost never load in english locales */
}
@font-face {
  font-family: 'Averta';
  src: url('../../shared/fonts/Averta-Extrabold-Basic.woff2') format('woff2'),
    url('../../shared/fonts/Averta-Extrabold-Basic.woff') format('woff');
  font-display: swap;
  font-weight: 900;
  unicode-range: U+A, U+D, U+20-7E, U+A9, U+D7, U+2010-201F, U+2026, U+2122, U+1F1F8, U+1F1FA; /* Basic Latin glyphs + some common extras */
}
@font-face {
  font-family: 'Averta';
  src: url('../../shared/fonts/Averta-Extrabold-Extended.woff2') format('woff2'),
    url('../../shared/fonts/Averta-Extrabold-Extended.woff') format('woff');
  font-display: swap;
  font-weight: 900;
  unicode-range: U+A1-A8, U+AA-D6, U+D8-2009, U+2027-2121, U+2123-1F1F7, U+1F1F9, U+1F1FB-E0000; /* Extended Glyphs: should almost never load in english locales */
}
