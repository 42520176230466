@font-face {
  font-family: 'Bogart-Semibold';
  src: url('../../shared/fonts/Bogart-Semibold-Basic.woff2') format('woff2'),
    url('../../shared/fonts/Bogart-Semibold-Basic.woff') format('woff');
  font-display: swap;
  unicode-range: U+A, U+D, U+20-7E, U+A9, U+D7, U+2010-201F, U+2026, U+2122, U+1F1F8, U+1F1FA; /* Basic Latin glyphs + some common extras */
}
@font-face {
  font-family: 'Bogart-Semibold';
  src: url('../../shared/fonts/Bogart-Semibold-Extended.woff2') format('woff2'),
    url('../../shared/fonts/Bogart-Semibold-Extended.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+A1-A8, U+AA-D6, U+D8-2009, U+2027-2121, U+2123-1F1F7, U+1F1F9, U+1F1FB-E0000; /* Extended Glyphs: should almost never load in english locales */
}

.font-bogart {
  font-family: Bogart-Semibold, Georgia, serif !important;
  font-weight: normal !important;
}
