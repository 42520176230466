@import '../utilities/variables';
@import '../utilities/mixins';

.city-search-text {
  a {
    text-decoration: none;
  }

  @include xs-only {
    font-size: 12px;
  }
  @include sm-up {
    font-size: 14px;
  }
}

footer,
.footer {
  background-color: $background-color-lighter;
  color: $text-color;
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
}

.footer {
  line-height: normal;
  padding: 20px 0 0 0;

  .footer-section-title {
    // Match h4 styling
    display: block;
    margin: 11px 0;
    color: $text-color-primary;
    font-family: $font-family-sans-serif;
    font-size: 20px;
    font-weight: 700 !important;
  }

  .footer-links-wrapper {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;

    @include xs-only {
      flex-direction: column;

      .learn-more-links,
      .about-rover-links,
      .help-links-and-badges,
      .cookie-consent-link {
        width: 100%;
      }

      .footer-section-title {
        font-weight: 700 !important;
      }
    }

    .learn-more-links .footer-list,
    .help-links-and-badges .footer-list,
    .about-rover-links .footer-list {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
    }

    .about-rover-links .footer-list {
      min-width: 125px;
    }

    .learn-more-links {
      flex: 27%;
      margin-bottom: 8px;

      @include xs-only {
        div {
          display: flex;
        }
      }
    }

    .help-links-and-badges {
      flex: 33%;
      margin-bottom: 8px;
      @include xs-only {
        margin-bottom: 0px;
      }
    }

    .about-rover-links,
    .footer-links {
      flex: 20%;
      margin-bottom: 8px;
    }

    .about-rover-links {
      flex: 40%;
      margin-bottom: 4px;
    }

    .top-cities-links {
      flex: 60%;
      div {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .footer-list {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      a {
        color: $text-color-tertiary;
        text-decoration: none;
      }
      a:hover,
      a:active {
        text-decoration: underline;
      }

      .small-column {
        @include xs-only {
          max-width: 140px;
        }
      }
    }

    .cookie-consent-link {
      margin-top: 15px;
      a {
        span {
          margin-left: 6px;
        }
        &:hover,
        &:active {
          text-decoration: none;
          span {
            text-decoration: underline;
          }
        }
        svg {
          vertical-align: middle;
        }
      }
    }
  }

  .marketing-footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
    @include xs-only {
      flex-direction: column;
      justify-content: center;
    }
    .marketing-email-capture {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      max-width: 350px;
      margin-top: 0;
      padding-left: 0;

      .help-text {
        height: auto;
        a {
          text-decoration: underline;
        }
      }

      @include xs-only {
        .help-text {
          font-style: italic;
          a:link {
            text-decoration: underline;
          }
        }
        .footer-section-title {
          font-weight: 700 !important;
        }
      }

      @include sm-only {
        align-items: flex-start;
      }

      a:link,
      a:visited {
        color: $text-color-tertiary;
      }
      color: $text-color-tertiary;

      .privacy-policy {
        padding-left: 5px;
        @media screen and (max-width: 360px) {
          max-width: 340px;
        }
      }

      .js-email-capture-footer-form-initial {
        width: 100%;
      }

      .js-email-capture-form {
        display: flex;

        .footer-email-input {
          display: inline-block;
          vertical-align: middle;
          flex: 1 1 225px;
        }
        .rover-btn {
          @include xs-only {
            width: 90px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          @media screen and (max-width: 320px) {
            width: 85px;
          }
        }
      }
    }

    .marketing-social {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 16px;
      width: 100%;

      @include md-up {
        align-items: flex-start;
      }

      .social-footer-links {
        display: flex;

        a {
          display: block;
          height: 33px;

          &:not(:first-child) {
            margin-left: 5px;
          }

          i {
            font-size: 30px;

            &.rover-icon-facebook-footer {
              color: $facebook-color;
            }
            &.rover-icon-instagram-footer {
              color: $brand-instagram-base;
            }
            &.rover-icon-twitter-footer {
              color: $brand-twitter-base;
            }
            &.rover-icon-pinterest-footer {
              color: $brand-pinterest-base;
            }
          }
        }
      }
    }
  }

  .marketing-footer-email {
    @include xs-only {
      align-items: flex-start;
      padding-left: 0;
      padding-top: 10px;
    }
    @include sm-only {
      align-items: flex-start;
      padding-left: 0;
      padding-top: 10px;
    }
  }

  .marketing-footer-social {
    @include xs-only {
      padding-left: 0;
    }
    @include sm-only {
      flex-direction: column;
      justify-content: center;
    }
  }

  .footer-copyright-and-address {
    align-items: flex-start;
    background-color: $background-color-lighter;
    color: $text-color-tertiary;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    height: 48px;
    justify-content: left;
    margin-top: 16px;
    margin-left: 0;
    padding-left: 0;
    padding-top: 16px;
    width: 100%;
    @include sm-up {
      .copyright {
        margin-right: 5px;
      }
    }
    @include xs-only {
      height: 74px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-left: 16px;
      padding-top: 24px;
      margin-top: 12px;
      margin-bottom: 4px;
      .copyright {
        margin-bottom: 8px;
      }
    }
    @include sm-only {
      justify-content: center;
    }
    @media screen and (max-width: 360px) {
      padding-top: 10px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
