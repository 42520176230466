/* Workaround for Ketch: This is to avoid the checkboxes for the
 * cookie preferences page in Ketch to not have z-index: 0 after
 * applying the tab-focus mixin, this value will make the button
 * not behave correctly */
#lanyard_root {
  input[type='checkbox'] {
    z-index: 1;
  }
}

#lanyard_root button[aria-controls="overviewTab"] {
  display: none;
}
