/* ==========================================================================
 * Utility classes for building layouts
 * ==========================================================================*/

/* Responsive Grid
 * ==========================================================================
 * We use bootstrap's standard responsive grid
 * @see - http://getbootstrap.com/css/#grid
 */

/* Bootstrap grid helpers
* ========================================================================== */

@import "../utilities/mixins";
@import "../components/headers";

.full-bleed-xs {
  @include xs-only {
    @include full-bleed;
  }
}

.full-bleed {
  @include full-bleed;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}
.no-margin-right {
  margin-right: 0 !important;
}
.no-shadow {
  box-shadow: none !important;
}

.nopadding-row {
  margin-right: 0;
  margin-left: 0;

  .nopadding-col {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.col-center {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

@include xs-only {
  @include row-lopad-size("xs");
}

@include sm-only {
  @include row-lopad-size("sm");
}

@include md-only {
  @include row-lopad-size("md");
}

@include lg-only {
  @include row-lopad-size("lg");
}

// Prevent the page from going responsive
.new-design-non-responsive {
  @extend .static-navbars;
  min-width: $container-desktop;

  .container {
    width: $container-desktop;
    max-width: none !important;

    &.navbar-container {
      width: auto;
    }
  }
}

/* Fluid Grid
 * ==========================================================================
 * Use this for placing variable-width blocks next to eachother that never
 * collapse into a stack
 */

.fluid-row {
  display: table;
  width: 100%;
  padding-left: 0; // for <ul class="fluid-row"> elements

  &.auto-width {
    width: auto;
  }

  .fluid-col {
    display: table-cell;
    vertical-align: top;
  }

  &.v-center,
  &.v-bottom {
    height: 100%;
  }

  &.v-bottom {
    .fluid-col {
      vertical-align: bottom;
    }
  }

  &.v-center {
    .fluid-col {
      vertical-align: middle;
    }
  }
}

/* Margins and Padding
 * ==========================================================================
 * We generate margin and padding classes
 * that follow the form .[padding|margin]-x[multiplier] and
 * .[padding|margin]-[direction]-x[multiplier]
 *
 * Examples:
 * .padding-x1 - gives you 1 unit of padding all around
 * .margin-top-x4 - gives you 4 units of top margin
 * .padding-right-x2 - gives you one unit of right padding
 * .margin-v-x4 - gives you 4 units of top and bottom margin
 *
 * These classes help us keep spacing consistent throughout the site, so
 * use them instead of writing custom margins and padding.
 *
 * These classes are responsive, so on small screens the spacing will be
 * reduced automatically.
 */

$spacing-types: (margin, padding);
$spacing-directions: (top, right, bottom, left);
$max-spacing-multiplier: 20;

@each $spacing-type in $spacing-types {
  @for $i from 0 through $max-spacing-multiplier {
    // Build out classes like .padding-x1, .margin-x2, etc.
    .#{$spacing-type}-x#{$i} {
      @include responsive-spacing($spacing-type, $i, true);
    }

    // Build out directional classes like .padding-right-x1, .margin-top-x1, etc.
    @each $spacing-direction in $spacing-directions {
      .#{$spacing-type}-#{$spacing-direction}-x#{$i} {
        @include responsive-spacing(
          $spacing-type + "-" + $spacing-direction,
          $i,
          true
        );
      }

      // Build out negative margin classes (useful for pulling block elements up, right, left)
      @if ($spacing-type == "margin") {
        .#{$spacing-type}-#{$spacing-direction}-negative-x#{$i} {
          @include responsive-spacing(
            $spacing-type + "-" + $spacing-direction,
            -1 * $i,
            true
          );
        }
      }
    }

    // Build out vertical classes like padding-v-x1
    .#{$spacing-type}-h-x#{$i} {
      @include responsive-spacing($spacing-type + "-left", $i, true);
      @include responsive-spacing($spacing-type + "-right", $i, true);
    }

    // Build out horizontal classes like padding-h-x1
    .#{$spacing-type}-v-x#{$i} {
      @include responsive-spacing($spacing-type + "-top", $i, true);
      @include responsive-spacing($spacing-type + "-bottom", $i, true);
    }
  }
}

.nomargin {
  margin: 0 !important;
}

.nopadding {
  padding: 0 !important;
}

/* Alignment
 * ==========================================================================
 * For alignment, use bootstrap's conveniently included classes:
 * .pull-left - floats left
 * .pull-right - floats right
 * .center-block - centers
 */

/* Borders
 * ========================================================================== */

.noborder,
.no-border {
  border: 0 !important;
}

.bordered {
  border: 1px solid $border-color;
}

.bordered-bottom {
  border-bottom: 1px solid $border-color;
}

.bordered-top {
  border-top: 1px solid $border-color;
}

.border-top-none {
  border-top: 0 !important;
}

table.border-top-none th {
  border-top: 0 !important;
}

@include xs-only {
  .bordered-xs {
    border: 1px solid $border-color;
  }
}

@include sm-only {
  .bordered-sm {
    border: 1px solid $border-color;
  }
}

@include md-only {
  .bordered-md {
    border: 1px solid $border-color;
  }
}

@include lg-only {
  .bordered-lg {
    border: 1px solid $border-color;
  }
}

.bordered-bottom-divider {
  border-bottom: 1px solid $gray-1;
}

.bordered-top-divider {
  border-top: 1px solid $gray-1;
}

/* Misc
 * ========================================================================== */

.inline-block {
  display: inline-block;
}

.prevent-break {
  white-space: nowrap;
}

// Always make .hide mean display: none
.hidden-xs,
.hidden-sm,
.hidden-md,
.hidden-lg,
.visible-lg,
.visible-md,
.visible-sm,
.visible-xs {
  &.hide {
    display: none !important;
  }
}

.full-width {
  width: 100% !important;
}

.ellipsis-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.vertically-align {
  @include vertically-align;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.visibility-hidden {
  visibility: hidden;
}

.rounded {
  border-radius: $border-radius-base;
}

/* ==========================================================================
 * Deprecated - Don't Use
 * ========================================================================== */

.table-row {
  display: table;
  height: 100%;
}

.table-cell {
  float: none;
  display: table-cell;
  vertical-align: middle;
}

.table-row > [class*="col-"] {
  @include table-cell;
}

// Responsive table-row
@include xs-only {
  @include table-row-size("xs");
}

@include sm-only {
  @include table-row-size("sm");
}

@include md-only {
  @include table-row-size("md");
}

@include lg-only {
  @include table-row-size("lg");
}

.auto-centered {
  margin: 0 auto;
}

.padded {
  padding: 10px;
}

.padded-bottom {
  padding-bottom: 10px;
}

.margined {
  margin: 10px 0;
}

.margined-bottom {
  margin-bottom: 10px;
}

.h-margined {
  margin: 0 10px;
}

.margined-all {
  margin: 10px;
}

.super-margined {
  margin: 20px 0;
}

.super-margined-all {
  margin: 20px;
}

.no-top-margin {
  margin-top: 0;
}

.grid-margin {
  margin: 10px 10px 0 0;
}

@media (min-width: $screen-sm-min) {
  .col-sm-15 {
    position: relative;
    min-height: 1px;
    width: 20%;
    float: left;
  }
}

.minw-xs {
  min-width: $screen-xs * 0.75;
}

.maxw-xs {
  max-width: $screen-xs;
}

.minw-sm {
  min-width: $screen-sm;
}
.maxw-sm {
  max-width: $screen-sm;
}

.minw-md {
  min-width: $screen-md;
}
.maxw-md {
  max-width: $screen-md;
}

.md-one-third {
  @include media-breakpoint-up(md) {
    min-width: 330px;
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
  }
}

.md-one-half {
  @include media-breakpoint-up(md) {
    min-width: 480px;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}

.user-select-none {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
}
