/** Updated March 2016 **/
// Author: Jake Chapman
//
// **** Please do not use any of the variables from above any longer. ****

// **********************
// Rover Brand Colors
// **********************

// brand-color func
// @param $color {String - required} - [primary, secondary, tertiary, gray, notice, danger]
// @param $name {String - optional} - Number or 'base' for gray brand color. Default: 1
// @param $opacity {Num - optional} - The amount to decrease the opacity by, between 0 and 1 - Default: 1
// @return rgba representation of Rover brand color
//
// Usage:
// all params - $primary-1: brand-color(primary, 2, 1);
// color & name - $primary-1: brand-color(primary, 2); // defaults to alpha:1
// named params - $primary-1: brand-color($color:primary, $name:2, $opacity:1);
// color & opacity - $primary-1: brand-color(primary, $opacity: 0.5);
@function brand-color($color, $name: null, $opacity: 1) {
  @if ($name != null) {
    @return rgba(map-get(map-get($brand-colors, $color), $name), $opacity);
  } @else {
    @return rgba(map-get(map-get($brand-colors, $color), 1), $opacity);
  }
}

// Brand Colors Nested Map
$brand-colors: (
  white: (
    1: rgb(255, 255, 255),
  ),
  primary: (
    1: rgb(4, 152, 90),
    2: rgb(0, 121, 64),
  ),
  secondary: (
    1: rgb(23, 163, 181),
    2: rgb(21, 122, 135),
  ),
  tertiary: (
    1: rgb(194, 47, 59),
    2: rgb(255, 117, 37),
  ),
  gray: (
    base: rgb(244, 245, 246),
    0: rgb(232, 235, 237),
    1: rgb(207, 215, 222),
    2: rgb(207, 215, 222),
    3: rgb(102, 114, 124),
    4: rgb(102, 114, 124),
    5: rgb(64, 72, 79),
    6: rgb(27, 31, 35),
  ),
  notice: (
    1: rgb(255, 190, 61),
  ),
  danger: (
    1: rgb(194, 47, 59),
  ),
  quick-match: (
    1: rgb(105, 100, 189),
  ),
);

// Brand
$brand-rover: rgb(0, 189, 112);

// White
$white: rgb(255, 255, 255);

// Gray
$gray-base: brand-color(gray, base);
$gray-0: brand-color(gray, 0);
$gray-1: brand-color(gray, 1);
$gray-2: brand-color(gray, 2);
$gray-3: brand-color(gray, 3);
$gray-4: brand-color(gray, 4);
$gray-5: brand-color(gray, 5);
$gray-6: brand-color(gray, 6);

// Primary
$primary-1: brand-color(primary, 1);
$primary-2: brand-color(primary, 2);

// Secondary
$secondary-1: brand-color(secondary, 1);
$secondary-2: brand-color(secondary, 2);

// Tertiary
$tertiary-1: brand-color(tertiary, 1);
$tertiary-2: brand-color(tertiary, 2);

// Notice
$notice: brand-color(notice);
$notice-light: #fdf6da;
$notice-accent: #40484f;

// Danger
$danger: brand-color(danger);

// On-demand Dog Walking
$quick-match: brand-color(quick-match);

// Social Colors
$facebook-color: #3b5998;
$twitter-color: #55acee;
$pinterest-color: #c8232c;
$gplus-color: #dd4b39;

// NEW COLORS 2021
$neutral-100: #f4f5f6;
$neutral-200: #e6e8eb;
$neutral-300: #d7dce0;
$neutral-400: #c9cfd4;
$neutral-500: #9ea5ac;
$neutral-600: #767c82;
$neutral-700: #62686e;
$neutral-800: #404347;
$neutral-900: #1f2124;
$neutral-white: #ffffff;
$neutral-black: #000000;

$green-100: #ebfff6;
$green-200: #c2f5dd;
$green-300: #61e8a7;
$green-400: #26c97a;
$green-500: #00bf63;
$green-600: #04985a;
$green-700: #007940;
$green-800: #015635;
$green-900: #053321;

$blue-100: #ecf1fb;
$blue-200: #c5d5f2;
$blue-300: #a3bdeb;
$blue-400: #7da1e2;
$blue-500: #5685da;
$blue-600: #2e67d1;
$blue-700: #2d5cb1;
$blue-800: #24498c;
$blue-900: #172f5b;

$cyan-100: #e6f8fc;
$cyan-200: #b6f0f9;
$cyan-300: #78e4f5;
$cyan-400: #43d0e5;
$cyan-500: #20c7e1;
$cyan-600: #17a3b5;
$cyan-700: #157a87;
$cyan-800: #14555e;
$cyan-900: #0f2f33;

$red-100: #fcefef;
$red-200: #ffc9ca;
$red-300: #ff9195;
$red-400: #ff727e;
$red-500: #ff5961;
$red-600: #c22f3b;
$red-700: #992a31;
$red-800: #661d22;
$red-900: #331013;

$orange-100: #fcf5ef;
$orange-200: #ffd4a8;
$orange-300: #ffa96e;
$orange-400: #ff8a46;
$orange-500: #ff7525;
$orange-600: #e0621b;
$orange-700: #bb4f12;
$orange-800: #77320b;
$orange-900: #331706;

$yellow-100: #fcf6eb;
$yellow-200: #ffecbd;
$yellow-300: #ffd76a;
$yellow-400: #ffbe3d;
$yellow-500: #ffab1c;
$yellow-600: #cf911f;
$yellow-700: #99691d;
$yellow-800: #66491b;
$yellow-900: #332612;

$brand-rover-base: #00bd70;
$brand-dog-buddy-base: #ff5856;
$brand-facebook-base: #3b5998;
$brand-instagram-base: #8a3ab9;
$brand-twitter-base: #1da1f2;
$brand-pinterest-base: #c8232c;
$brand-google-base: #dd4b39;

// NEW DESIGN TOKENS 2019

// Background Tokens
$background-color-darker: $neutral-900;
$background-color-dark: $neutral-800;

$background-color-light: $neutral-200;
$background-color-lighter: $neutral-100;
$background-color-lightest: $neutral-white;

$background-color-disabled: $neutral-200;

$background-color-primary-with-white-text: $green-700; // $primary-1 doesn't have sufficient color contrast for a11y
$background-color-error: $red-100;

// Text Tokens
$text-color-primary: $neutral-900;
$text-color-primary-inverse: $neutral-100;

$text-color-secondary: $neutral-800;
$text-color-secondary-inverse: $neutral-100;

$text-color-tertiary: $neutral-700;
$text-color-tertiary-inverse: $neutral-200;

$text-color-disabled: $neutral-500;

$text-color-link: $blue-600;
$text-color-link-hover: $blue-700;
$text-color-link-inverse: $neutral-white;
$text-color-link-inverse-hover: $neutral-100;

$text-color-link-secondary: $neutral-800;
$text-color-link-secondary-hover: $neutral-900;
$text-color-link-secondary-inverse: $neutral-100;
$text-color-link-secondary-inverse-hover: $neutral-200;

$text-color-error: $red-600;

$text-color-price: $orange-700;

// Border Tokens
$border-radius: 4px;

$border-color-primary: $neutral-400;
$border-color-secondary: $neutral-300;

$border-color-disabled: $neutral-100;
$border-color-error: $red-600;

$separator-color-primary: $neutral-300;

// Interactive Tokens
$interactive-color-default: $primary-1;
$interactive-color-default-hover: $green-700;
$interactive-color-default-focus: $green-700;
$interactive-color-default-active: $primary-1;

$interactive-color-primary: $primary-1;
$interactive-color-primary-hover: $green-700;
$interactive-color-primary-focus: $green-700;
$interactive-color-primary-active: $primary-1;

$interactive-color-destructive: $red-600;
$interactive-color-destructive-hover: $red-700;
$interactive-color-destructive-focus: $red-700;
$interactive-color-destructive-active: $red-600;

$interactive-color-disabled: $neutral-100;

// *** Legacy Component Color VARS *** //
// Deprecated and will be removed and refactored where necessary

// Button Colors
$secondary-1-btn-bg: $secondary-1; // Deprecated - Refactor to _buttons.scss
$secondary-1-btn-bg-highlight: $secondary-2; // Deprecated - Refactor to _buttons.scss
$green-btn-bg: $primary-1; // Deprecated - Refactor to _buttons.scss
$green-btn-bg-highlight: $primary-2; // Deprecated - Refactor to _buttons.scss
$orange-btn-bg: $tertiary-1; // Deprecated - Refactor to _buttons.scss
$orange-btn-bg-highlight: $tertiary-2; // Deprecated - Refactor to _buttons.scss
$white-btn-bg: #ffffff; // Deprecated - Refactor to _buttons.scss

// New Brand Colors
$new-brand-green: $secondary-1; // Deprecated - Remove
$new-brand-dark-blue: $secondary-2; // Deprecated - Remove
$new-brand-orange: $tertiary-2; // Deprecated - Remove

// Text colors
$gray-lightest: brand-color(white, 1, 0.7); // Deprecated - Remove

// Deprecated
$grey: $gray-2; // Deprecated
$grey-light: $gray-1; // Deprecated

$navbar-color: #f3f3f3; // Deprecated - Refactor to navbar component
$navbar-default-link-color: $text-color-secondary; // Deprecated - Refactor to navbar component
$navbar-default-color: $text-color-secondary; // Deprecated - Refactor to navbar component

$border-color: $gray-1; // Deprecated
