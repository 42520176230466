body {
  padding: 0;
  margin: 0;

  h3 {
    margin-top: 0;
  }

  table {
    .input {
      vertical-align: top;
    }
  }

  input,
  label {
    margin: 0;
  }
}
