@import 'buttons';

.rover-btn-group {
  @extend .btn-group;

  .rover-btn {
    height: 40px;
  }

  // z-index ensures active button borders sit on top of siblings
  .rover-btn-default {
    color: $text-color-tertiary;
    &.active {
      background-color: $background-color-lightest;
      border-color: $neutral-800;
      box-shadow: inset 0 0 0 1px $neutral-800;
      color: $text-color-primary;
      z-index: 3;
    }

    &:hover {
      color: $text-color-primary;
    }

    &:focus,
    &:focus-within {
      @include tab-focus(5, 2);
    }

    &:not(:first-child) {
      margin-left: -2px;
    }
  }
}

// This is a fix for dobule borders between button groups in
// Bootstrap's .btn-group-justified
.rover-btn-group-justified {
  display: flex;
  flex-direction: row;

  .rover-btn {
    padding: 9px 0px 7px;
  }

  .rover-btn-default {
    width: 100%;
    border: none;
    box-shadow: inset 0 0 0 1px $gray-1;

    &.active {
      box-shadow: inset 0 0 0 2px $primary-1;
    }
  }
}

// Workaround for Bootstrap button issues on iOS. Allows availability
// button groups in sitter profile to have same style as Bootstrap button
// groups, without actually applying Bootstrap button script to them.
.availability-group > .btn > input[type='radio'],
.availability-group > .btn > input[type='checkbox'] {
  position: absolute;
  z-index: -1;
  @include opacity(0);
}

@mixin rover-pretty-btn() {
  .rover-btn {
    border-width: $size-border-width-primary;
    color: $text-color-tertiary;
    background-color: $background-color-lightest;
    border-color: $border-color-primary;
    white-space: normal;
    margin-top: 0px;
    position: relative;

    .rover-item-checked {
      display: none;
    }

    &:hover {
      color: $text-color-primary;
    }

    &:active,
    &.active {
      border-width: $size-border-width-primary;
      box-shadow: inset 0 0 0 2px $interactive-color-default;
      color: $text-color-primary;
      border-color: $interactive-color-default;

      .rover-icon {
        color: $interactive-color-default;
      }

      .rover-item-checked {
        display: block;
        position: absolute;
        top: -12px;
        right: 0;

        .rover-icon,
        .fa {
          left: inherit;
        }

        @include xs-only {
          display: none;
        }
      }

      &:focus,
      &:focus-within {
        outline-offset: 2px;
      }
    }

    &:focus,
    &:focus-within {
      @include tab-focus();
    }
  }
}

.pretty-buttons {
  @include rover-pretty-btn;

  .rover-item-checked {
    display: none;
  }
}

.button-columns {
  @include flexbox;
  @include flex-wrap(wrap);
  @include justify-content(space-between);
  @include align-items(stretch);

  label {
    @include responsive-spacing('padding', 3);
  }

  &.button-columns-even {
    .rover-btn {
      width: 48%;
    }
  }

  @include not-xs {
    &.button-columns-2 {
      .rover-btn {
        width: 48%;
        margin-bottom: 0;
      }
    }

    &.button-columns-3 {
      .rover-btn {
        width: 31%;
      }
    }

    &.button-columns-4 {
      .rover-btn {
        width: 23%;
      }
    }

    &.button-columns-5 {
      .rover-btn {
        width: 18%;
      }
    }

    &.button-columns-11 {
      .rover-btn {
        width: 8%;
      }
    }
  }

  @include xs-only {
    &.button-columns-even,
    &.button-columns-odd {
      .rover-btn {
        margin-bottom: 15px;
      }
    }

    &.button-columns-2 {
      .rover-btn {
        margin-bottom: 0;
      }
    }

    &.button-columns-3 {
      .rover-btn {
        &:nth-child(3) {
          margin-bottom: 0;
        }
      }
    }

    &.button-columns-4 {
      .rover-btn {
        &:nth-child(n + 3) {
          margin-bottom: 0;
        }
      }
    }

    &.button-columns-5 {
      .rover-btn {
        &:nth-child(5) {
          margin-bottom: 0;
        }
      }
    }

    &.button-columns-11 {
      .rover-btn {
        width: 100%;
      }
    }
  }

  &.button-columns-inline-labels {
    .rover-icon {
      vertical-align: middle;
    }

    span.icon-label {
      @include responsive-spacing('margin-left', 1);
    }
  }

  input[type='radio'],
  input[type='checkbox'] {
    display: inherit;
    position: absolute;
    top: 0;
  }

  @include rover-pretty-btn;
}

// Flex Button Group
.flex-columns {
  display: flex;
  flex-direction: row;
  @include flex-wrap(wrap);
  @include justify-content(space-between);
  @include align-items(stretch);

  label {
    @include xs-only {
      font-size: 12px;
    }
  }

  .rover-btn {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 9px 0 7px 0;
    flex: 1 1 0;
    margin: 0 15px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    @include xs-only {
      margin: 0 5px;
    }

    .rover-icon-1x {
      font-size: 15px;
    }

    .rover-icon-2x {
      font-size: 20px;
    }

    .rover-icon-3x {
      font-size: 25px;
    }

    .rover-icon-4x {
      font-size: 30px;
    }
  }

  .rover-btn-default {
    width: 100%;
    border: none;
    box-shadow: inset 0 0 0 1px $gray-1;

    &.active {
      box-shadow: inset 0 0 0 2px $primary-1;
    }

    .rover-icon {
      vertical-align: middle;
    }

    span.icon-label {
      @include responsive-spacing('margin-left', 1);
    }
  }

  input[type='radio'],
  input[type='checkbox'] {
    display: inherit;
    position: absolute;
    top: 0;
  }

  @include rover-pretty-btn;
}
