/* ==========================================================================
 * Typography mixins and utility classes
 * ========================================================================== */

/* Headings
 * ========================================================================== */

@import '../vendor/bootstrap/scss/mixins';
@import '../utilities/mixins';
@import '../utilities/variables';

h1,
.h1 {
  @include h1;
}
h2,
.h2 {
  @include h2;
}
h3,
.h3 {
  @include h3;
}
h4,
.h4 {
  @include h4;
}
h5,
.h5 {
  @include h5;
}
h6,
.h6 {
  @include h6;
}

h5.desktop-heavy {
  @include h5;
  font-weight: bold;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $headings-font-family;
}

.kibble-display-title {
  font-family: $kibble-display-font-family;
}

.heavy-underline {
  font-weight: bold;

  &:after {
    @include responsive-spacing('margin-top', 3);
    display: block;
    content: '';
    width: 70px;
    border-top: 3px solid $primary-1;
  }
}

.heavy-with-underline {
  font-weight: bold;

  &::after {
    @include responsive-spacing('margin-top', 2);
    @include responsive-spacing('margin-bottom', 3);
    display: block;
    content: '';
    width: 50px;
    border: 2px solid $primary-1;
    border-radius: $border-radius-base;
  }

  &.text-center::after {
    margin-left: auto;
    margin-right: auto;
  }
}

/* Font Sizes
 * ========================================================================== */

.small-text,
small {
  @include small-text;
}

.large-text {
  @include large-text;
}

.body-text {
  @include body-text;
}

.text-large {
  font-size: $font-size-large;
}

.text-large-x1 {
  font-size: $font-size-h4;
}

.text-large-x2 {
  font-size: $font-size-h3;
}

.text-large-x3 {
  font-size: $font-size-h2;

  @include media-breakpoint-down(sm) {
    font-size: $font-size-h2 - 4;
  }
}

.text-large-x4 {
  font-size: $font-size-h1;

  @include media-breakpoint-down(sm) {
    font-size: $font-size-h1 - 4;
  }
}

/* Text Colors
 * ==========================================================================
 * Bootstrap provides:
 * .text-muted - for de-emphasized text
 */

.text-primary {
  color: $primary-1;
}
.text-secondary {
  color: $secondary-1;
}
.text-tertiary {
  color: $orange-700;
}
.text-quick-match {
  color: $quick-match;
}

.text-contrast {
  color: $text-color-primary-inverse !important;

  &:link {
    color: $text-color-link-inverse !important;
  }

  &:visited,
  &:hover,
  &:active {
    color: $text-color-primary-inverse !important;
  }

  &-muted {
    @extend .text-contrast;
    opacity: 0.7;

    &:link,
    &:visited,
    &:hover,
    &:active {
      opacity: 0.7;
    }
  }
}

a.text-contrast {
  text-decoration: underline;
}

// Set the color to the standard text color
.text-color-body,
a.text-color-body {
  color: $text-color;
}

// Social Colors
.text-color-twitter {
  color: $twitter-color;
}
.text-color-facebook {
  color: $facebook-color;
}
.text-color-gplus {
  color: $gplus-color;
}

/* Links
 * ========================================================================= */

.hover-no-decoration {
  &:hover {
    text-decoration: none;

    .parent-hover-underline {
      text-decoration: underline;
    }
  }
}

.link-dark {
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: $text-color;
  }
}

.as-link {
  @include link-style();
}

/* Font Variants
 * ========================================================================= */

// Underline
.text-underline {
  text-decoration: underline !important;
}

// Regular Italic
.emphasis {
  font-style: italic;
}

// Default
.base-text {
  font-weight: normal !important;

  &-i {
    font-weight: normal;
    font-style: italic;
  }
}

// Thin (300)
.thin-text {
  font-weight: 400;

  // Thin Italic
  &-i {
    font-weight: 400;
    font-style: italic;
  }
}

// Semibold (700)
.semibold-text {
  font-weight: 700;

  // Semibold Italic
  &-i {
    font-weight: 700;
    font-style: italic;
  }
}

// Bold (700)
.heavy-text {
  font-weight: bold;

  // Bold Italic
  &-i {
    font-weight: bold;
    font-style: italic;
  }
}

/* Capitalization
 * ========================================================================= */

.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}

/* Truncation
 * ========================================================================= */

// Truncates text to one-line
.text-truncate {
  @include text-overflow;
}

/* ==========================================================================
 * Deprecated - Don't use
 * ========================================================================= */

@include xs-only {
  @include text-center-size('xs');
}

@include sm-only {
  @include text-center-size('sm');
}

@include md-only {
  @include text-center-size('md');
}

@include lg-only {
  @include text-center-size('lg');
}

.fineprint {
  line-height: 80%;
}

.big-text {
  font-size: 120%;
}

.text-orange {
  color: $orange-700;
}

.text-aqua {
  color: $cyan-700;
}

.text-gray-light {
  color: $gray-light;
}

.heavy {
  font-weight: 700;
  font-family: 'Averta', Avenir-Heavy, sans-serif;
}

// Gray Palette Text Color Resets

.text-gray {
  @include text-color-variant($color: $gray-base);
}

.text-gray-1 {
  @include text-color-variant($color: $gray-1);
}

.text-gray-2 {
  @include text-color-variant($color: $gray-2);
}

.text-gray-3 {
  @include text-color-variant($color: $gray-3);
}

.text-gray-4 {
  @include text-color-variant($color: $gray-4);
}

.text-gray-5 {
  @include text-color-variant($color: $gray-5);
}
