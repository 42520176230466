@import '../../../kibble/tokens/build/scss/tokens';
@import '../utilities/variables';
@import '../utilities/mixins';
@import '../utilities/flexbox';
@import '../vendor/bootstrap/scss/mixins';
@import '../vendor/bootstrap/scss/forms';
@import '../vendor/bootstrap/scss/buttons';
@import '../vendor/bootstrap/scss/button-groups';
@import '../vendor/bootstrap/scss/input-groups';
@import '../layout/forms';

a[role='button'],
a.rover-btn {
  text-decoration: none;
}

/***** START Kibble Button Styles *****/
.kibble-btn {
  @extend .btn;
  font-weight: $weight-font-semibold;
  font-size: $typography-text-200-font-size;
  line-height: $typography-text-200-line-height;
  -webkit-font-smoothing: antialiased;
  white-space: normal;
  border-radius: $size-border-radius-round;
  text-decoration: none;
}

/***** Default Kibble Styles *****/
.kibble-btn-default {
  @include kibble-button-variant($color-text-secondary, $color-background-primary, none);
  border: 2px solid $color-border-primary;

  &:hover,
  &:focus {
    background: $color-background-secondary;
  }
}

.kibble-btn-default-contrast {
  @include kibble-button-variant($color-text-primary-inverse, $color-background-contrast, none);
  border: 2px solid $color-border-contrast;

  &:hover,
  &:focus {
    color: $color-text-secondary-inverse;
    background: $color-neutral-900;
  }
}

.kibble-btn-default-destructive {
  @include kibble-button-variant($color-text-error, $color-background-primary, none);
  border: 2px solid $color-border-primary;

  &:hover,
  &:focus {
    background: $color-background-error;
  }
}

.kibble-btn-default-contrast-destructive {
  @include kibble-button-variant($color-red-100, $color-background-contrast, none);
  border: 2px solid $color-border-contrast;

  &:hover,
  &:focus {
    color: $color-red-400;
    background: $color-red-900;
  }
}

/***** Primary Kibble Styles *****/
.kibble-btn-primary {
  @include kibble-button-variant($color-text-primary-inverse, $color-interactive-background-button-primary-base, none);
  box-shadow: 0px 2px 12px -1px rgba(27, 31, 35, 0.24);
  
  &:hover,
  &:focus {
    color: $color-text-primary-inverse;
    background: $color-interactive-background-button-primary-pressed;
    box-shadow: 0px 2px 12px -1px rgba(27, 31, 35, 0.24);
  }
}

.kibble-btn-primary-contrast {
  @include kibble-button-variant($color-text-primary-inverse, $color-interactive-background-button-primary-base, none);
  box-shadow: 0px 2px 12px -1px rgba(27, 31, 35, 0.24);
  
  &:hover,
  &:focus {
    color: $color-text-primary-inverse;
    background: $color-interactive-background-button-primary-pressed;
    box-shadow: 0px 2px 12px -1px rgba(27, 31, 35, 0.24);
  }
}

.kibble-btn-primary-destructive {
  @include kibble-button-variant($color-text-primary-inverse, $color-interactive-background-destructive-base, none);
  box-shadow: 0px 2px 12px -1px rgba(27, 31, 35, 0.24);
  
  &:hover,
  &:focus {
    background: $color-interactive-background-destructive-hover;
    box-shadow: 0px 2px 12px -1px rgba(27, 31, 35, 0.24);
  }
}

.kibble-btn-primary-contrast-destructive {
  @include kibble-button-variant($color-text-primary-inverse, $color-interactive-background-destructive-base, none);
  box-shadow: 0px 2px 12px -1px rgba(27, 31, 35, 0.24);
  
  &:hover,
  &:focus {
    background: $color-interactive-background-destructive-hover;
    box-shadow: 0px 2px 12px -1px rgba(27, 31, 35, 0.24);
  }
}

/***** Flat Kibble Styles *****/
.kibble-btn-flat {
  @include kibble-button-variant($color-text-secondary, transparent, none);

  &:hover,
  &:focus {
    color: $color-text-primary;
    background: $color-interactive-background-primary-pressed;
  }
}

.kibble-btn-flat-contrast {
  @include kibble-button-variant($color-text-secondary-inverse, transparent, none);

  &:hover,
  &:focus {
    color: $color-text-primary-inverse;
    background: $color-neutral-900;
  }
}

.kibble-btn-flat-destructive {
  @include kibble-button-variant($color-text-error, transparent, none);

  &:hover,
  &:focus {
    color: $color-red-700;
    background: $color-red-100;
  }
}

.kibble-btn-flat-contrast-destructive {
  @include kibble-button-variant($color-red-400, transparent, none);

  &:hover,
  &:focus {
    color: $color-red-300;
    background: $color-red-900;
  }
}

/***** Disabled Kibble Styles *****/
.kibble-btn-disabled {
  @include kibble-button-variant(
    $color-text-disabled,
    $color-interactive-background-disabled-base,
    none
  );
  border: 2px solid $color-interactive-border-disabled-base;
  box-shadow: none;
  pointer-events: none;
  cursor: not-allowed;

  &:hover,
  &:focus {
    color: $color-text-disabled;
    background: $color-interactive-background-disabled-base;
    border: 2px solid $color-interactive-border-disabled-base;
  }
}

/***** Kibble Button Sizes (Changes size of text and line-height only, use block styles to apply padding) *****/
.kibble-btn-small {
  font-size: $typography-text-100-font-size;
  line-height: $typography-text-100-line-height;
}

.kibble-btn-large {
  font-size: $typography-text-300-font-size;
  line-height: $typography-text-300-line-height;
}

/***** Kibble Button Blocks -  Padding + Full Width *****/
.kibble-btn-block-small {
  padding: $size-space-2-x $size-space-4-x;
}

.kibble-btn-block {
  padding: $size-space-3-x $size-space-6-x;
}

.kibble-btn-block-large {
  padding: $size-space-5-x $size-space-8-x;
}

.kibble-btn-full-width {
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
}

.kibble-btn-full-width-xs {
  @include xs-only {
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: center;
  }
}

/***** END Kibble Button Styles *****/

/***** START Legacy Rover Button Styles *****/

.rover-btn {
  @extend .btn;
  font-family: $font-family-sans-serif;
  font-weight: $weight-font-semibold;
  -webkit-font-smoothing: antialiased;
  white-space: normal;
  border-radius: $size-border-radius-round;

  &.circular {
    border-radius: 50%;
  }
}

.rover-btn-primary {
  @include rover-button-variant(white, $color-interactive-background-button-primary-base, none);
  background: $color-interactive-background-button-primary-base;
  box-shadow: 0px 2px 12px -1px rgba(27, 31, 35, 0.24);

  &:link,
  &:active,
  &:visited {
    background: $color-interactive-background-button-primary-base;
  }
  &:hover,
  &:focus {
    background: $color-interactive-background-button-primary-pressed;
    box-shadow: 0px 2px 12px -1px rgba(27, 31, 35, 0.24);
  }
}

// Use this class for primary buttons on top of a gray or black background.
.rover-btn-primary-contrast {
  @include rover-button-variant(white, $color-interactive-background-button-primary-base, none);
  background: $color-interactive-background-button-primary-base;
  box-shadow: 0px 2px 12px -1px rgba(27, 31, 35, 0.24);

  &:link,
  &:active,
  &:visited {
    background: $color-interactive-background-button-primary-base;
  }
  &:hover,
  &:focus {
    background: $color-interactive-background-button-primary-pressed;
    box-shadow: 0px 2px 12px -1px rgba(27, 31, 35, 0.24);
  }
}

.rover-btn-primary-accessible {
  @include rover-button-variant(white, $neutral-800, $neutral-800);
}

.rover-btn-secondary {
  @include rover-button-variant($gray-dark, $white, $gray-1);

  &:hover {
    background-color: $background-color-lighter;
    border-color: $border-color-primary;
  }
}

.rover-btn-secondary-contrast {
  @include rover-button-variant($color-text-primary-inverse, $color-background-contrast, $color-border-contrast);

  &:hover {
    background-color: $color-interactive-background-primary-active-pressed;
  }
}

.rover-btn-wide {
  @include rover-button-variant(white, $brand-primary, $brand-primary);
  padding: 8px 90px;
}

.rover-btn-quick-match,
.rover-btn-grooming-pilot {
  @include rover-button-variant(white, $blue-600, $blue-600);
}

.rover-btn-text-large {
  @include button-size(
    $padding-base-vertical,
    $padding-small-horizontal,
    $font-size-large,
    22px,
    $size-border-radius-round
  );
}

.rover-btn-transparent-dark {
  @include rover-button-variant(white, rgba(0, 0, 0, 0.25), transparent);

  // Unfortunately sass has trouble darkening rgba values
  &:hover {
    background-color: rgba(0, 0, 0, 0.35);
  }
}

.rover-btn-danger {
  @include rover-button-variant(white, $brand-danger, $brand-danger);
}

// *** DEPRECATED: USE .rover-btn-secondary *** //
.rover-btn-default {
  @extend .rover-btn-secondary;
}

.rover-btn-apple {
  @include rover-button($color: #000000, $highlight-color: lighten(#111111, 5%));
  border-radius: $size-border-radius-round;
}

.rover-btn-google {
  @include rover-button($color: #206FF2, $highlight-color: darken(#206FF2, 5%));
  border-radius: $size-border-radius-round;
}

.rover-btn-facebook {
  @include rover-button($color: #4063b3, $highlight-color: #3c599d);
  border-radius: $size-border-radius-round;
  padding-right: $size-space-4-x;
  padding-left: $size-space-4-x;
}

.rover-btn-twitter {
  @include rover-button($color: #55acee, $highlight-color: darken(#55acee, 5%));
  border-radius: $size-border-radius-round;
}

.rover-btn-favorite {
  @extend .kibble-btn;
  padding: $size-space-4-x;

  .rover-icon {
    color: $green-600;
  }
  .default-state {
    display: flex;
  }
  .favorited-state {
    display: none;
  }

  &:hover {
    background: $color-interactive-background-primary-pressed;
  }

  &.favorited {
    @extend .kibble-btn;
    padding: $size-space-4-x;
    border: 2px solid $green-500;
    background-color: $green-500;

    &:hover {
      border-color: $green-600;
      background-color: $green-600;
    }

    .rover-icon {
      color: $text-color-primary-inverse;
    }
    .favorited-state {
      display: flex;
    }
    .default-state {
      display: none;
    }

    &.favoriting {
      .rover-icon {
        animation: hatch 2s ease-in-out;
        transform-origin: 50% 100%;
        visibility: visible !important;
      }
    }
  }
}

.btn-right-icon {
  margin-left: 3px;
}

.btn-left-icon {
  margin-right: 3px;
}

.button-as-link {
  @include link-style();
  padding: 0;
  margin: 0;
}

.button-as-link-no-underline {
  @include link-style();
  padding: 0;
  margin: 0;
  text-decoration: none;
}

.button-as-link-muted {
  @include link-style();
  color: $gray-3;
  padding: 0;
  margin: 0;
}

.button-as-link::-moz-focus-inner {
  border: 0;
  padding: 0;
}

@include xs-only {
  .btn-block:not(.button-as-link) {
    font-size: $font-size-large;
    white-space: normal;
  }
}

.btn-block-xs {
  @include xs-only {
    display: block;
    width: 100%;
  }
}

.rover-btn-next-section {
  @extend .btn-group;
  @include not-xs {
    @include flexbox;
    @include align-items(stretch);
    @include justify-content(center);
  }

  border: 0;
  background: none;
  padding: 0;
  width: 100%;

  div.rover-btn {
    @include responsive-spacing('padding-top', 5);
    @include responsive-spacing('padding-bottom', 5);
    border: 0 !important;
    margin: 0 !important;

    @include xs-only {
      width: 100%;
    }
  }

  div.rover-btn:first-child {
    @include border-radius($border-radius-base);

    @include not-xs {
      @include flex(1, 1, 0);
    }

    background: $secondary-2;
    border-color: $secondary-2;
    font-weight: lighter;
    color: white;

    br {
      display: none;
    }
    @include xs-only {
      br {
        display: initial;
      }
    }

    div {
      @include responsive-spacing('padding-left', 3);
    }

    .rover-icon {
      @include vertically-align;
      @include responsive-spacing('padding-left', 2);

      position: absolute;
      left: 0px;
      font-size: 2em;
    }

    @include xs-only {
      border-top-right-radius: $border-radius-base !important;
      border-top-left-radius: $border-radius-base !important;
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      white-space: normal;
    }

    strong {
      font-weight: bolder;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      z-index: 0;
    }
  }

  div.rover-btn:not(:first-child) {
    background: $secondary-1;
    border-color: $secondary-1;

    @include not-xs {
      @include flex(0, 0, auto);
    }

    @include xs-only {
      border-bottom-right-radius: $border-radius-base !important;
      border-bottom-left-radius: $border-radius-base !important;
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      z-index: 1;
    }
  }

  // Disabled state
  .rover-btn-next-section-disabled-body {
    display: none;
  }
  .rover-btn-next-section-enabled-body {
    display: block;
  }
  &.disabled {
    .rover-btn-next-section-disabled-body {
      display: block;
    }
    .rover-btn-next-section-enabled-body {
      display: none;
    }
    cursor: not-allowed;
    pointer-events: none;

    div.rover-btn {
      &:first-child {
        border-radius: $border-radius-base !important;
      }

      &:not(:first-child) {
        display: none;
      }
    }
  }
}

@mixin rover-button-tabs($nav-theme: $primary-1) {
  display: flex;
  $border-size: 1px;
  padding: 0;
  margin: 0;
  text-align: center;

  div.rover-button-wrapper {
    display: flex;
    flex-grow: 1;
  }

  @media (min-width: $screen-xs-max) {
    div.rover-button-wrapper:first-child {
      padding-left: 0px;
    }

    div.rover-button-wrapper:last-child {
      padding-right: 0px;
    }
  }

  @media (max-width: $screen-xs-max) {
    div.rover-button-wrapper:not(first-child) {
      @include responsive-spacing('margin-top', 1);
    }

    div.rover-button-wrapper:not(last-child) {
      @include responsive-spacing('margin-bottom', 1);
    }
  }

  div.rover-button {
    flex-grow: 1;
    position: relative;
    border: $border-size solid $border-color;
    border-radius: $border-radius-base;
    background: white;
    cursor: pointer;

    &:not(:last-child) {
      /*margin-right: 10px;*/
    }

    &.active {
      .rover-icon {
        color: $nav-theme;
      }
    }

    &:focus-within {
      @include tab-focus(0, 2px);
    }
  }
}

.service-select-buttons {
  @include rover-button-tabs();
}

.arrow-btn {
  & > i {
    position: relative;
    top: 1px;
    left: 6px;
  }
}
