// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 544px, md: 768px)
//
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.

@import 'variables';
@import '../vendor/bootstrap/scss/mixins';

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 544px, md: 768px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md))
//    md
@function breakpoint-next(
  $name,
  $breakpoints: $grid-breakpoints,
  $breakpoint-names: map-keys($breakpoints)
) {
  $n: index($breakpoint-names, $name);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 544px, md: 768px))
//    544px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.1.
//
//    >> breakpoint-max(sm, (xs: 0, sm: 544px, md: 768px))
//    767px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 1px, null);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  @include media-breakpoint-up($name, $breakpoints) {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  @include media-breakpoint-up($lower, $breakpoints) {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

@mixin hover {
  @if $enable-hover-media-query {
    // See Media Queries Level 4: http://drafts.csswg.org/mediaqueries/#hover
    // Currently shimmed by https://github.com/twbs/mq4-hover-shim
    @media (hover: hover) {
      &:hover {
        @content;
      }
    }
  } @else {
    &:hover {
      @content;
    }
  }
}

@mixin hover-focus {
  @if $enable-hover-media-query {
    &:focus {
      @content;
    }
    @include hover {
      @content;
    }
  } @else {
    &:focus,
    &:hover {
      @content;
    }
  }
}

@mixin plain-hover-focus {
  @if $enable-hover-media-query {
    &,
    &:focus {
      @content;
    }
    @include hover {
      @content;
    }
  } @else {
    &,
    &:focus,
    &:hover {
      @content;
    }
  }
}

@mixin hover-focus-active {
  @if $enable-hover-media-query {
    &:focus,
    &:active {
      @content;
    }
    @include hover {
      @content;
    }
  } @else {
    &:focus,
    &:active,
    &:hover {
      @content;
    }
  }
}

@mixin small() {
  @media screen and (max-width: $screen-xs-min) {
    @content;
  }
}

@mixin border-radius($border-radius: 6px) {
  border-radius: $border-radius;
}

@mixin medium() {
  @media screen and (max-width: $screen-md-min) {
    @content;
  }
}

@mixin partial-border($border-width: 20%, $border-height: 3px) {
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: $border-height;
    width: $border-width;
    border-bottom: $border-height solid $highlight-color;
  }
}

@mixin rover-alert-variant($background) {
  background: white;
  border: 1px solid $gray-base;
  border-left: 10px solid $background;
  border-radius: $border-radius-base;
  padding: 15px;
  margin-bottom: 15px;
  font-size: 14px;

  & > .close {
    right: 0;
  }
}

@mixin text-color-variant($color) {
  color: $color;
  &:link {
    text-decoration: underline;
  }
}

@mixin absolute-alert {
  .alert {
    z-index: $zindex-navbar - 1;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    margin: 0 100px;
  }
}

@mixin kibble-button-variant($color, $background, $border) {
  background: $background;
  border-color: $border;
  color: $color;
  
  &:hover,
  &:focus {
    box-shadow: none;  // supress some global button styles
  }
}

@mixin rover-button-variant($color, $background, $border) {
  background: $background;
  border-color: $border;
  color: $color;

  &:hover,
  &:focus,
  &:active,
  &.active {
    box-shadow: none;
    color: $color;
  }

  &:focus,
  &:active,
  &.active {
    background: darken($background, 15%);
    border-color: darken($background, 15%);
  }

  &:hover,
  &:focus {
    background-color: darken($background, 5%);
    border-color: darken($background, 5%);
  }

  &:active,
  &.active {
    background-color: darken($background, 10%);
    border-color: darken($background, 10%);
  }
}

@mixin rover-button(
  $color: $secondary-1-btn-bg,
  $highlight-color: $secondary-1-btn-bg-highlight,
  $text-color: white,
  $text-shadow: true
) {
  @include border-radius($border-radius-base);
  @include transition(background 100ms linear);

  background: $color;
  border-color: $highlight-color;
  color: $text-color;

  @if $text-shadow {
    text-shadow: 0px -1px 0 rgba(0, 0, 0, 0.2);
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    box-shadow: none;
    color: $text-color;
  }

  &:focus,
  &:active,
  &.active {
    background: darken($highlight-color, 15%);
  }

  &.btn-lg {
    @extend .btn-lg;
  }

  &.btn-sm {
    @extend .btn-sm;
  }

  &.btn-xs {
    @extend .btn-xs;
  }

  &.text-left {
    text-align: left;
  }
  &.text-right {
    text-align: right;
  }

  &:hover,
  &:focus {
    background-color: darken($color, 5%);
  }

  &:active,
  &.active {
    background-color: darken($color, 10%);
  }
}

@mixin inner-shadow($depth: 40px, $darkness: 100, $opacity: 0.5) {
  @include box-shadow(inset rgba($darkness, $darkness, $darkness, 0.5) 0 0 $depth 0);
}

@mixin box-drop-shadow {
  @include box-shadow(0 3px 8px -2px rgba(0, 0, 0, 0.15));
}

@mixin placeholder-text($color) {
  &::-webkit-input-placeholder {
    /* WebKit browsers */
    color: $color;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $color;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: $color;
  }
}

// Responsive media query utilities, to wrap any css using the appropriate media query
@mixin xs-only {
  @media (max-width: $screen-xs-max) {
    @content;
  }
}

@mixin sm-only {
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    @content;
  }
}

@mixin sm-up {
  @media (min-width: $screen-sm-min) {
    @content;
  }
}

@mixin sm-down {
  @media (max-width: $screen-md-min) {
    @content;
  }
}

@mixin md-only {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    @content;
  }
}

@mixin md-up {
  @media (min-width: $screen-md-min) {
    @content;
  }
}

@mixin md-down {
  @media (max-width: $screen-lg-min) {
    @content;
  }
}

@mixin lg-only {
  @media (min-width: $screen-lg-min) {
    @content;
  }
}

@mixin lg-up {
  @media (min-width: $screen-lg-min) {
    @content;
  }
}

@mixin not-xs {
  @media (min-width: $screen-sm-min) {
    @content;
  }
}

@mixin not-lg {
  @media (max-width: $screen-lg-min) {
    @content;
  }
}

// Other shared mixins
@mixin icon-heading-circle {
  border-radius: 50%;
  color: white;
  display: inline-block;
  font-size: 18px;
  height: 32px;
  line-height: 28px;
  margin-right: 10px;
  position: relative;
  top: -4px;
  width: 32px;
  border: 2px solid black;
  @include inner-shadow(10px, 255, 1);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  @include xs-only {
    font-size: 23px;
  }
}

@mixin table-row {
  display: table;
  height: 100%;
}

@mixin table-cell {
  float: none;
  display: table-cell;
  vertical-align: middle;
}

@mixin table-row-size($size) {
  .table-row-#{$size} {
    @include table-row;
    > [class*='col-'] {
      @include table-cell;
    }
  }
}

@function compute-responsive-spacing($multiplier) {
  $spacing-unit: 5px;
  @return $multiplier * $spacing-unit;
}

@mixin responsive-spacing($prop, $multiplier, $important: false) {
  $sm-screen-ratio: 0.8;
  $computed-spacing: compute-responsive-spacing($multiplier);

  @if $important == true {
    #{$prop}: $computed-spacing !important;
  } @else {
    #{$prop}: $computed-spacing;
  }

  @media (max-width: $screen-xs-max) {
    @if $important == true {
      #{$prop}: $computed-spacing * $sm-screen-ratio !important;
    } @else {
      #{$prop}: $computed-spacing * $sm-screen-ratio;
    }
  }
}

@mixin heading-base {
  color: $headings-color;
}

@mixin h1 {
  @include heading-base;
  font-family: $headings-font-family;
  font-size: $font-size-h1;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;

  @include xs-only {
    font-size: 36px;
  }
}

@mixin h2 {
  @include heading-base;
  font-family: $headings-font-family;
  font-size: $font-size-h2;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;

  @include xs-only {
    font-size: 28px;
  }
}

@mixin h3 {
  @include heading-base;
  font-family: $headings-font-family;
  font-size: $font-size-h3;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;

  @include xs-only {
    font-size: 22px;
  }
}

@mixin h4 {
  @include heading-base;
  font-family: $headings-font-family;
  font-size: $font-size-h4;
  line-height: $headings-line-height;

  @include xs-only {
    font-size: 18px;
  }
}

@mixin h5 {
  @include heading-base;
  font-family: $headings-font-family;
  font-size: $font-size-h5;
  font-weight: $headings-small-font-weight;
  line-height: $headings-line-height;

  @include xs-only {
    font-size: 16px;
  }
}

@mixin h6 {
  @include heading-base;
  font-family: $headings-font-family;
  font-size: $font-size-h6;
  font-weight: $headings-small-font-weight;
  line-height: $headings-line-height;

  @include xs-only {
    font-size: 14px;
  }
}

@mixin small-text {
  font-size: $font-size-small;
  line-height: $line-height-small;
}

@mixin large-text {
  font-size: $font-size-large;
  line-height: $line-height-large;
}

@mixin body-text {
  font-size: $font-size-base;
  line-height: $line-height-base;
}

@mixin text-muted {
  color: $text-muted;
}

@mixin link-style {
  background-color: transparent;
  border: none;
  color: $link-color;
  cursor: pointer;
  display: inline;
  text-decoration: underline;
  &:hover {
    color: $link-hover-color;
    &:disabled,
    &.disabled {
      text-decoration: none;
    }
  }
  &:disabled,
  &.disabled {
    color: $text-muted;
    cursor: default;
  }
  &.unstyled {
    color: $text-color;
    &:hover {
      text-decoration: none;
      color: $text-color;
    }
  }
  &.text-muted {
    color: $text-muted;
  }
}

@mixin text-center-size($size) {
  .text-center-#{$size} {
    text-align: center;
  }
}

@mixin full-bleed() {
  margin-left: calc($grid-gutter-width/2 * -1);
  margin-right: calc($grid-gutter-width/2 * -1);
}

@mixin row-lopad-size($size) {
  .row-lopad-#{$size} {
    margin-right: -5px;
    margin-left: -5px;
  }
  .col-lopad-#{$size} {
    padding-right: 5px;
    padding-left: 5px;
  }
}

// Vertical alignment helper
@mixin vertically-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

// Forces a full-width div to maintain an aspect ratio
@mixin fixed-aspect-ratio($w, $h) {
  height: 0;
  padding-top: calc($h/($w/100%));
}

@mixin timepicker-list() {
  .ui-timepicker-wrapper {
    margin-top: 4px;
    width: 150px;

    & .ui-timepicker-list > li {
      padding: 5px 0 5px 5px;
    }
  }

  .ui-timepicker-list li {
    color: $text-color;
  }

  li.ui-timepicker-selected,
  .ui-timepicker-list li:hover,
  .ui-timepicker-list .ui-timepicker-selected:hover {
    background: $gray-base;
    color: $text-color;
  }
}
