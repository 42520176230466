@import '../../../kibble/tokens/build/scss/tokens';
@import '../utilities/variables';
@import '../vendor/bootstrap/scss/mixins';
@import '../vendor/bootstrap/scss/scaffolding';
@import '../utilities/mixins';

form label {
  font-weight: bold;
}

.form-control {
  padding: $padding-base-vertical;
  box-shadow: none;
  border: 2px solid $gray-1;

  &[readonly] {
    cursor: default;
    background-color: $input-bg;
    color: $text-color;
  }

  &[type='text'] {
    -webkit-appearance: none;
  }
}

// crispy-forms adds this to labels for required fields
.asteriskField {
  display: none;
}

// Need to do this due to how hidden-xs works on this element
// COMMENT THESE OUT TO GET INSPECTION WORKING WTF CHROME
@media (min-width: $screen-sm-min) {
  .input-group-addon {
    display: table-cell !important;
  }
}

.daterangepicker-row {
  position: relative;
  margin: 0 -15px;
}

.input-group-wrapper {
  background-color: white;
  @include border-radius(4px);
}

.input-group-label {
  text-align: center;
  font-size: 12px;
  padding: 3px 0;
  border-bottom: 1px solid lighten($border-color, 10%);
  margin: 3px 0 0;
  .fa {
    margin-right: 3px;
  }
}

.input-group.with-seamless-addon {
  @include box-shadow(none);
  width: 100%;
  border: none;
}

.date-picker-control {
  text-align: left;
  background-color: transparent;
  box-shadow: none;
  width: 100%;
  border-radius: $border-radius-small !important;
  display: block;
}

.date-picker-control-addon {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
  z-index: 99;
  width: 50px;
  height: 100%;
  font-size: 18px;
  color: $gray-2;
  border-radius: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
  pointer-events: none;

  .rover-icon {
    vertical-align: middle;
  }
}

.has-error .date-picker-control-addon {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid;
  border-left: none;
}

.big-radio {
  width: 28px;
  height: 16px;
}

label.form-control-inline,
input.form-control-inline[type='radio'],
input.form-control-inline[type='checkbox'] {
  margin-top: 0;
  margin-bottom: 0;
}

input.disable-label:disabled + label,
button[disabled],
input[disabled],
.disabled {
  cursor: not-allowed;
  // This is the minimum opacity under which default text (27, 31, 35)
  // will pass color contrast guidelines on a white (#ffffff) background
  opacity: 0.61;
}

.crispy-hide-show {
  .crispy-hide-show-trigger-wrap {
    @include responsive-spacing('margin-bottom', 2);
  }
}

.switcher {
  position: relative;
  width: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &:focus-within {
    @include tab-focus();
  }
}

.switcher.pull-right + label {
  clear: both;
  padding-left: 0;
}

.switcher-checkbox {
  @extend .sr-only;
}

.switcher-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 14px;
  padding: 0;
  line-height: 14px;
  border-radius: 20px;
  background-color: $neutral-200;
}

.switcher-label:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  margin: -3px;
  background: $white;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 31px;
  border-radius: 26px;
  border: $size-border-width-primary solid $border-color-primary;
  transition: all 0.2s ease-in 0s;
}

.switcher-checkbox:checked + .switcher-label {
  background-color: $neutral-500;
}

.switcher-checkbox:checked + .switcher-label,
.switcher-checkbox:checked + .switcher-label:before {
  border-color: $neutral-800;
}

.switcher-checkbox:checked + .switcher-label .switcher-inner {
  margin-left: 0;
}

.switcher-checkbox:checked + .switcher-label:before {
  right: 0px;
  background-color: $neutral-800;
  border: 1px solid $neutral-800;
}

.num-units {
  select {
    width: initial;
  }
}
