
/**
 * Do not edit directly
 * Generated on Mon, 03 Mar 2025 20:21:30 GMT
 */

$color-background-primary: #ffffff;
$color-background-secondary: #f4f5f6;
$color-background-tertiary: #e6e8eb;
$color-background-contrast: #404347;
$color-background-accent: #fcf6eb;
$color-background-accent-secondary: #ecf1fb;
$color-background-overlay: rgba(31, 33, 36, 0.48);
$color-background-overlay-accent: rgba(22, 154, 91, 0.24);
$color-background-error: #ffede8;
$color-background-caution: #fcf6eb;
$color-background-success: #f1fdf6;
$color-background-info: #f4f5f6;
$color-background-highlight: #e8f9fc;
$color-background-attention-highlight: #f8b816;
$color-background-attention-blue: #c5d5f2;
$color-border-primary: #c9cfd4;
$color-border-secondary: #d7dce0;
$color-border-contrast: #62686e;
$color-border-error: #bc4338;
$color-border-caution: #f8b816;
$color-border-success: #1a824e;
$color-border-info: #c9cfd4;
$color-border-highlight: #116876;
$color-border-input-focus: #2e67d1;
$color-blue-100: #ecf1fb;
$color-blue-200: #c5d5f2;
$color-blue-300: #a3bdeb;
$color-blue-400: #7da1e2;
$color-blue-500: #5685da;
$color-blue-600: #2e67d1;
$color-blue-700: #2d5cb1;
$color-blue-800: #24498c;
$color-blue-900: #172f5b;
$color-cyan-100: #e8f9fc;
$color-cyan-200: #8be2ef;
$color-cyan-300: #39cde4;
$color-cyan-400: #1cb0c7;
$color-cyan-500: #1893a7;
$color-cyan-600: #127787;
$color-cyan-700: #116876;
$color-cyan-800: #0d535d;
$color-cyan-900: #09363d;
$color-green-100: #f1fdf6;
$color-green-200: #bae8c9;
$color-green-300: #6bd094;
$color-green-400: #05b86c;
$color-green-500: #169a5b;
$color-green-600: #1a824e;
$color-green-700: #1b6c42;
$color-green-800: #1b5535;
$color-green-900: #173724;
$color-orange-100: #fcf5ef;
$color-orange-200: #ffd4a8;
$color-orange-300: #ffa96e;
$color-orange-400: #ff8a46;
$color-orange-500: #ff7525;
$color-orange-600: #e0621b;
$color-orange-700: #bb4f12;
$color-orange-800: #77320b;
$color-orange-900: #331706;
$color-pink-100: #fff2f7;
$color-pink-200: #ffd6e8;
$color-pink-300: #ffa6cc;
$color-pink-400: #ff7db1;
$color-pink-500: #ff66a3;
$color-pink-600: #d13880;
$color-pink-700: #99215f;
$color-pink-800: #330a22;
$color-pink-900: #331706;
$color-red-100: #ffede8;
$color-red-200: #ffc8bc;
$color-red-300: #ffa494;
$color-red-400: #ff7665;
$color-red-500: #e6564a;
$color-red-600: #bc4338;
$color-red-700: #a03f37;
$color-red-800: #7d342d;
$color-red-900: #4f231f;
$color-yellow-100: #fcf6eb;
$color-yellow-200: #ffecbd;
$color-yellow-300: #ffd76a;
$color-yellow-400: #f8b816;
$color-yellow-500: #d59418;
$color-yellow-600: #b77f1d;
$color-yellow-700: #80561a;
$color-yellow-800: #654418;
$color-yellow-900: #412c13;
$color-neutral-100: #f4f5f6;
$color-neutral-200: #e6e8eb;
$color-neutral-300: #d7dce0;
$color-neutral-400: #c9cfd4;
$color-neutral-500: #9ea5ac;
$color-neutral-600: #767c82;
$color-neutral-700: #62686e;
$color-neutral-800: #404347;
$color-neutral-900: #1f2124;
$color-neutral-white: #ffffff;
$color-neutral-black: #000000;
$color-brand-rover: #00bd70;
$color-brand-dogbuddy: #ff5a5b;
$color-brand-facebook: #3b5998;
$color-brand-instagram: #8a3ab9;
$color-brand-twitter: #1da1f2;
$color-brand-x: #000000;
$color-brand-pinterest: #c8232c;
$color-brand-google: #4285f4;
$color-brand-apple: #000000;
$color-interactive-primary: #2e67d1;
$color-interactive-primary-pressed: #2d5cb1;
$color-interactive-background-primary-base: #ffffff;
$color-interactive-background-primary-pressed: #f4f5f6;
$color-interactive-background-primary-active: #404347;
$color-interactive-background-primary-active-pressed: #1f2124;
$color-interactive-background-button-primary-base: #2e67d1;
$color-interactive-background-button-primary-pressed: #2d5cb1;
$color-interactive-background-destructive-base: #bc4338;
$color-interactive-background-destructive-hover: #a03f37;
$color-interactive-background-disabled-base: #f4f5f6;
$color-interactive-border-primary-base: #c9cfd4;
$color-interactive-border-primary-pressed: #767c82;
$color-interactive-border-primary-active: #404347;
$color-interactive-border-primary-active-pressed: #1f2124;
$color-interactive-border-disabled-base: #e6e8eb;
$color-interactive-text-primary-base: #404347;
$color-interactive-text-primary-active: #2e67d1;
$color-interactive-text-disabled-base: #9ea5ac;
$color-interactive-text-destructive-base: #bc4338;
$color-interactive-text-link-primary-base: #2e67d1;
$color-interactive-text-link-primary-pressed: #2d5cb1;
$color-interactive-text-link-primary-inverse-base: #ffffff;
$color-interactive-text-link-primary-inverse-pressed: #f4f5f6;
$color-interactive-text-link-secondary-base: #404347;
$color-interactive-text-link-secondary-pressed: #1f2124;
$color-interactive-text-link-secondary-inverse-base: #f4f5f6;
$color-interactive-text-link-secondary-inverse-pressed: #e6e8eb;
$color-interactive-link-primary-base: #2e67d1;
$color-interactive-link-primary-hover: #24498c;
$color-interactive-link-primary-visited: #2e67d1;
$color-interactive-link-primary-inverse-base: #a3bdeb;
$color-interactive-link-primary-inverse-hover: #ecf1fb;
$color-interactive-link-primary-inverse-visited: #c5d5f2;
$color-interactive-link-secondary-base: #62686e;
$color-interactive-link-secondary-hover: #1f2124;
$color-interactive-link-secondary-visited: #a3bdeb;
$color-interactive-link-secondary-inverse-base: #f4f5f6;
$color-interactive-link-secondary-inverse-hover: #d7dce0;
$color-interactive-link-secondary-inverse-visited: #f4f5f6;
$color-separator-primary: #d7dce0;
$color-temporary-button-tertiary-background-pressed: rgba(125, 161, 226, 0.24);
$color-temporary-button-destructive-background-pressed: rgba(255, 118, 101, 0.24);
$color-temporary-button-primary-disabled-background: rgba(26, 130, 78, 0.24);
$color-text-primary: #1f2124;
$color-text-primary-inverse: #ffffff;
$color-text-secondary: #404347;
$color-text-secondary-inverse: #f4f5f6;
$color-text-tertiary: #62686e;
$color-text-tertiary-inverse: #e6e8eb;
$color-text-placeholder: #62686e;
$color-text-disabled: #9ea5ac;
$color-text-price: #bb4f12;
$color-text-error: #bc4338;
$color-text-caution: #654418;
$color-text-success: #1b6c42;
$shadow-elevation-high: 0px 8px 10px -6px rgba(27, 31, 35, 0.22);
$shadow-elevation-mid: 0px 2px 12px -1px rgba(27, 31, 35, 0.24);
$shadow-elevation-low: 0px 1px 4px 0px rgba(27, 31, 35, 0.32);
$shadow-border-primary: 0px 0px 0px 2px rgba(207, 215, 222, 1);
$shadow-border-contrast: 0px 0px 0px 2px rgba(102, 114, 124, 1);
$shadow-border-disabled: 0px 0px 0px 2px rgba(232, 235, 237, 1);
$size-border-radius-primary: 8px;
$size-border-radius-secondary: 4px;
$size-border-radius-round: 99999px;
$size-border-width-primary: 2px;
$size-border-width-secondary: 1px;
$size-space-negative-64-x: -256px;
$size-space-negative-48-x: -192px;
$size-space-negative-40-x: -160px;
$size-space-negative-32-x: -128px;
$size-space-negative-24-x: -96px;
$size-space-negative-20-x: -80px;
$size-space-negative-16-x: -64px;
$size-space-negative-12-x: -48px;
$size-space-negative-10-x: -40px;
$size-space-negative-8-x: -32px;
$size-space-negative-6-x: -24px;
$size-space-negative-5-x: -20px;
$size-space-negative-4-x: -16px;
$size-space-negative-3-x: -12px;
$size-space-negative-2-x: -8px;
$size-space-negative-1-x: -4px;
$size-space-0-x: 0px;
$size-space-1-x: 4px;
$size-space-2-x: 8px;
$size-space-3-x: 12px;
$size-space-4-x: 16px;
$size-space-5-x: 20px;
$size-space-6-x: 24px;
$size-space-8-x: 32px;
$size-space-10-x: 40px;
$size-space-12-x: 48px;
$size-space-16-x: 64px;
$size-space-20-x: 80px;
$size-space-24-x: 96px;
$size-space-32-x: 128px;
$size-space-40-x: 160px;
$size-space-48-x: 192px;
$size-space-64-x: 256px;
$size-font-50: 13px;
$size-font-100: 14px;
$size-font-200: 16px;
$size-font-300: 20px;
$size-font-400: 26px;
$size-font-500: 32px;
$size-font-600: 38px;
$size-font-400-responsive: 32px;
$size-font-500-responsive: 40px;
$size-font-600-responsive: 48px;
$typography-display-400-font-family: Bogart-Semibold;
$typography-display-400-font-size: 26px;
$typography-display-400-font-weight: 700;
$typography-display-400-line-height: 1.25;
$typography-display-400-margin-bottom: 20px;
$typography-display-500-font-family: Bogart-Semibold;
$typography-display-500-font-size: 32px;
$typography-display-500-font-weight: 700;
$typography-display-500-line-height: 1.25;
$typography-display-500-margin-bottom: 24px;
$typography-display-600-font-family: Bogart-Semibold;
$typography-display-600-font-size: 38px;
$typography-display-600-font-weight: 700;
$typography-display-600-line-height: 1.25;
$typography-display-600-margin-bottom: 32px;
$typography-display-responsive-400-font-size: 32px;
$typography-display-responsive-500-font-size: 40px;
$typography-display-responsive-600-font-size: 48px;
$typography-heading-50-font-family: Averta;
$typography-heading-50-font-size: 13px;
$typography-heading-50-font-weight: 700;
$typography-heading-50-line-height: 1.25;
$typography-heading-50-margin-bottom: 8px;
$typography-heading-100-font-family: Averta;
$typography-heading-100-font-size: 14px;
$typography-heading-100-font-weight: 700;
$typography-heading-100-line-height: 1.25;
$typography-heading-100-margin-bottom: 8px;
$typography-heading-200-font-family: Averta;
$typography-heading-200-font-size: 16px;
$typography-heading-200-font-weight: 700;
$typography-heading-200-line-height: 1.25;
$typography-heading-200-margin-bottom: 12px;
$typography-heading-300-font-family: Averta;
$typography-heading-300-font-size: 20px;
$typography-heading-300-font-weight: 700;
$typography-heading-300-line-height: 1.25;
$typography-heading-300-margin-bottom: 16px;
$typography-heading-400-font-family: Averta;
$typography-heading-400-font-size: 26px;
$typography-heading-400-font-weight: 700;
$typography-heading-400-line-height: 1.25;
$typography-heading-400-margin-bottom: 20px;
$typography-heading-500-font-family: Averta;
$typography-heading-500-font-size: 32px;
$typography-heading-500-font-weight: 700;
$typography-heading-500-line-height: 1.25;
$typography-heading-500-margin-bottom: 24px;
$typography-heading-600-font-family: Averta;
$typography-heading-600-font-size: 38px;
$typography-heading-600-font-weight: 700;
$typography-heading-600-line-height: 1.25;
$typography-heading-600-margin-bottom: 32px;
$typography-heading-responsive-400-font-size: 32px;
$typography-heading-responsive-500-font-size: 40px;
$typography-heading-responsive-600-font-size: 48px;
$typography-paragraph-50-font-family: Averta;
$typography-paragraph-50-font-size: 13px;
$typography-paragraph-50-font-weight: 400;
$typography-paragraph-50-line-height: 1.5;
$typography-paragraph-50-margin-bottom: 12px;
$typography-paragraph-100-font-family: Averta;
$typography-paragraph-100-font-size: 14px;
$typography-paragraph-100-font-weight: 400;
$typography-paragraph-100-line-height: 1.5;
$typography-paragraph-100-margin-bottom: 12px;
$typography-paragraph-200-font-family: Averta;
$typography-paragraph-200-font-size: 16px;
$typography-paragraph-200-font-weight: 400;
$typography-paragraph-200-line-height: 1.5;
$typography-paragraph-200-margin-bottom: 16px;
$typography-paragraph-300-font-family: Averta;
$typography-paragraph-300-font-size: 20px;
$typography-paragraph-300-font-weight: 400;
$typography-paragraph-300-line-height: 1.5;
$typography-paragraph-300-margin-bottom: 24px;
$typography-text-50-font-family: Averta;
$typography-text-50-font-size: 13px;
$typography-text-50-font-weight: 400;
$typography-text-50-line-height: 1.25;
$typography-text-100-font-family: Averta;
$typography-text-100-font-size: 14px;
$typography-text-100-font-weight: 400;
$typography-text-100-line-height: 1.25;
$typography-text-200-font-family: Averta;
$typography-text-200-font-size: 16px;
$typography-text-200-font-weight: 400;
$typography-text-200-line-height: 1.5;
$typography-text-300-font-family: Averta;
$typography-text-300-font-size: 20px;
$typography-text-300-font-weight: 400;
$typography-text-300-line-height: 1.2;
$weight-font-regular: 400;
$weight-font-semibold: 700;
$ratio-line-height-heading: 1.25;
$ratio-line-height-paragraph: 1.5;
$ratio-line-height-text: 1.25;
$ratio-line-height-text-large: 1.2;