/* ==========================================================================
 * Styles that don't fit into any other stylesheet.
 * ========================================================================== */

@import 'utilities/variables';
@import 'utilities/mixins';
@import 'vendor/bootstrap/scss/mixins';
@import 'vendor/bootstrap/scss/utilities';

// Rules for intial page vertical spacing
body {
  // Fragile way for now of making sure there is space for
  // customer support number
  $nav-content-gap: 40px;
  color: $text-color-primary;

  // avoid cases where the footer isn't all the way at the bottom of the page
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  // IE11 flex fixes. See: FE-3759, DEV-36843, DEV-38442
  & > .navbar-container {
    flex: 0 0 auto;
  }

  & > .rover-main-content-wrap {
    flex: 1 1 auto;
  }

  & > .footer-container {
    flex: 0;
  }

  // Establish initial spacing between nav and content
  @include sm-up {
    .rover-main-content-wrap {
      margin-top: $nav-content-gap;
    }

    &.flush {
      .rover-main-content-wrap {
        margin-top: 0;
        transition: margin-top, 0.4s, ease-out;
        &.in {
          margin-top: 610px;
        }
      }
    }
  }
}

.lazy-background {
  background-image: url('../images/lazy-image-placeholder.png') !important;
}

.flag-listing {
  color: $state-danger-text;
  &:hover,
  &:active {
    color: $state-danger-text;
  }
}

.shadow-line {
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}

.rounded-section {
  border: 1px solid $border-color;
  border-radius: 6px;
  overflow: hidden;
}

.SnapABug_Button {
  @include xs-only {
    display: none !important;
  }
}

.emoji {
  width: 20px;
  height: 20px;
  margin: 0 1px;
}

.mobile-embedded {
  padding-top: 0px;

  .SnapABug_Button {
    display: none;
  }

  .rover-primary-content-block {
    margin-bottom: 0;
  }

  .rover-widget-block {
    margin-bottom: 0;
  }

  .rover-content-section {
    border-top: none;
  }
}

.embedded-ios {
  .rover-content-section-title {
    color: #aaa;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 17px;
  }
}

.embedded-android {
  .rover-content-section-title {
    color: $primary-1;
    font-size: 14px;
    line-height: 20px;
  }
}

/* Override jQuery UI accordion font selection */
.ui-widget-content {
  input,
  select,
  textarea,
  button {
    font-family: $font-family-base;
  }
}

// responsive text alignment
.text-xs-left {
  text-align: left;
}
.text-xs-right {
  text-align: right;
}
.text-xs-center {
  text-align: center;
}
.text-xs-justify {
  text-align: justify;
}

@media (min-width: $screen-sm-min) {
  .text-sm-left {
    text-align: left;
  }
  .text-sm-right {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
  .text-sm-justify {
    text-align: justify;
  }
}

@media (min-width: $screen-md-min) {
  .text-md-left {
    text-align: left;
  }
  .text-md-right {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
  .text-md-justify {
    text-align: justify;
  }
}

@media (min-width: $screen-lg-min) {
  .text-lg-left {
    text-align: left;
  }
  .text-lg-right {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
  .text-lg-justify {
    text-align: justify;
  }
}

.rebook-modal .modal-dialog {
  @include not-xs {
    width: 660px;
  }
}

/* Temporary styles which should be removed once their bearers are */
.hp-holiday-charity-banner {
  background-color: #6ab3a8;
}

.pointer {
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
}

.desaturate {
  -webkit-filter: grayscale(100%);
  filter: gray;
  filter: grayscale(100%);
}

a {
  cursor: pointer;
}

.help-text {
  display: inline-block;
  height: 26px;
  position: relative;
  left: -5px;
  top: 1px;

  & > i {
    margin: 6px;
  }
}

.mobile-embedded .hidden-mobile-embedded {
  @extend .hidden;

  // Remove padding from select .form-controls on mobile-embedded to fix a bug in cordova
  select.form-control {
    padding: 0;
  }
}

.dropdown-menu.typeahead li.active a {
  background-color: $gray-base;
  color: $gray-5;
}

.section-toggle {
  display: none;
}

.checkbox,
.radio {
  label {
    color: $text-color-primary;
  }
}

.instapage-embedded-form {

  .instapage-embedded-form-heading {
    font-size: 17px;
    font-weight: 700;

    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  // Overwrite global setting that causes this div to take up too much space
  #fb-root {
    flex: none;
  }
}
