@import '../utilities/variables';

.fa.yellow {
  color: #f6e518;
}

// Facebook icon with blue background
.icon-facebook {
  color: white;
  padding: 2px 2px 0px 6px;
  background-color: $facebook-color;
}

.icon-notice {
  color: $notice;
}

.icon-request-booked {
  + span {
    font-weight: 700;
    font-size: 16px;
  }
  margin-right: 4px;
  vertical-align: top;
  font-size: 24px;
  color: #00bd70;
}

.rating-stars {
  .rover-icon {
    color: $notice;
  }
}

.icon-encircled {
  border: 1px solid white;
  border-radius: 100px;
  padding: 6px;
}

[class^='rover-icon-'],
[class*=' rover-icon-'] {
  display: inline-block;
}

// these font-awesome copy/paste styles were renamed fr for font-rover
.fr-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.fr-stack-1x,
.fr-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.fr-stack-1x {
  line-height: inherit;
}
.fr-stack-2x {
  font-size: 2em;
}

.fr-spin {
  animation: spin 2s infinite linear;
}

// Icon Sizes
.rover-icon {
  &.rover-icon-lg {
    font-size: calc(4em / 3.25);
    line-height: calc(3em / 3);
    vertical-align: middle;
  }
  &.rover-icon-2x {
    font-size: 2em;
  }
  &.rover-icon-3x {
    font-size: 3em;
  }
  &.rover-icon-4x {
    font-size: 4em;
  }
  &.rover-icon-5x {
    font-size: 5em;
  }

  &.rover-icon-md {
    font-size: 14px;
    vertical-align: -10%;
  }
  &.kibble-rover-icon-1x {
    font-size: 1.5em;
  }
}
