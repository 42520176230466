// WebKit-style focus

@mixin tab-focus($zIndex: 0, $outlineOffset: 2px) {
  // Mozilla
  outline: 5px auto Highlight;
  // WebKit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: $outlineOffset;
  z-index: $zIndex;
}
