@import '../base/colors';

.popover-title {
  font-size: 16px;
  color: $text-color-primary !important;
  background-color: $background-color-lighter !important;
  border-color: $border-color-primary !important;
}

.popover-content {
  color: $text-color-primary !important;
  background-color: $background-color-lightest !important;
  border-color: $border-color-primary !important;
}

.popover-list {
  font-size: 12px;
  margin: 0 0 16px 20px;
  padding: 0;
}

.popover-extra {
  font-size: 11px;
  text-align: center;
}

.popover-tiny-copy {
  font-size: 10px;
}

.popover-max-width {
  max-width: 100%;
}

/* Fix arrow misaligned in small width */
.popover.top .arrow {
  top: 100% !important;
}
