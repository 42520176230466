/* ==========================================================================
 * Header Groups - title + subtitle
 * ========================================================================== */

@import '../utilities/mixins';
@import '../utilities/flexbox';
@import '../../../kibble/tokens/build/scss/tokens';

@mixin rover-header-group($bottom-spacing: 4) {
  @include responsive-spacing('margin-bottom', $bottom-spacing);

  .rover-header-title {
    margin: 0;
  }

  .kibble-display-title {
    font-family: $font-family-serif;
    margin: 0;
  }

  .rover-header-subtitle {
    color: $text-muted;
    margin-bottom: 0;
    @include responsive-spacing('margin-top', 1);
  }
}

.rover-header-group {
  @include rover-header-group;
}

/* Rover Header
 * ========================================================================== */
body {
  &.static-navbars {
    .header-toolbar-wrapper,
    .navbar-default {
      .navbar-wrapper {
        width: 100%;
        background-color: $white;
        position: relative;
        z-index: 100; // above the our services dropdown
      }
    }
  }

  // Sticky navbar
  &.sticky-navbar {
    @mixin make-navbar-sticky {
      .navbar-container {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 100; // this should be synchronized with ZIndex.js
      }
    }
  }

  .navbar-container {
    min-height: 60px;
  }

  .skip-to-main-content-link {
    position: absolute;
    left: 50%;
    padding: 16px;
    color: #007940; // GREEN_700 not available as a variable inside SCSS
    font-size: 16px; // HEADING_200_FONT_SIZE
    background: #ebfff6; // GREEN_100, also INTERACTIVE_BACKGROUND_COLOR_PRIMARY_ACTIVE
    border: 1px solid transparent;
    border-bottom-left-radius: $border-radius-base;
    border-bottom-right-radius: $border-radius-base;
    transform: translate(-50%, -200%);
    transition: transform 0.3s;
    z-index: $zindex-modal-background - 1; // appear above admin toolbar and COVID banner

    &:focus {
      transform: translate(-50%, 0%);
      z-index: $zindex-modal-background - 1; // otherwise z-index: 0 from tab-focus() overrides
    }
  }
}

header,
.header {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
}

.idv-logo-icon-wrapper{
  font-size:35px;
  color: $brand-rover !important;
  margin-bottom:12px;
}
.idv-header{
  display: flex;
  justify-content: center;
}

.header {
  #mobile-react-notifications-center {
    .notifications-center-wrapper {
      margin: 15px 15px 0 0;
    }
  }

  .link-icon {
    margin-right: 4px;
    vertical-align: middle;
    margin-bottom: 2px;
  }

  .link-svg {
    margin-right: 4px;
    vertical-align: middle;
    margin-bottom: 2px;
    fill: $brand-rover;
  }
  .megaphone-icon {
    margin-right: 4px;
    margin-bottom: 2px;
    height: 18px;
    width: 18px;

  }
  .navbar-brand {
    padding: 8px 0 0 0;
    margin-right: 20px;
    font-size: 35px;
    color: $brand-rover;

    &:hover,
    &:focus {
      color: $brand-rover;
    }
  }
  .navbar-toggle {
    background-color: $white;
    &:hover,
    &:focus {
      background-color: $white;
    }
    .icon-bar {
      background-color: $primary-1;
    }
  }
  .dropdown-with-avatar {
    padding-bottom: 11px;
    .avatar {
      height: 24px;
      width: 24px;
      border: 1px solid $white;
    }
  }

  .navbar-nav > .open > a {
    &,
    &:hover,
    &:focus {
      $open-padding: ($nav-link-padding - 1);
      padding-left: $open-padding;
      padding-right: $open-padding;
      z-index: 1001;
      border: 1px solid $border-color;
      border-width: 0 1px;
    }
  }

  .navbar-nav > li > .dropdown-menu {
    border-color: $gray-1;
    box-shadow: none;
    border-radius: 0 0 4px 0;
  }

  .navbar-nav > .open > .dropdown-menu {
    border-bottom: 1px solid $gray-1;
    border-top: none;
  }

  .nav .dropdown-toggle .caret {
    margin-top: 0;
  }

  &.navbar-default {
    @include flexbox;
    @include flex-direction(column);
    background: white;
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid $gray-1;
    margin-bottom: 0px;

    > div {
      order: 10;
    }
  }

  .search-location-header-view {
    width: 180px;

    @include lg-only {
      margin-right: 10px;
    }
  }
}

@include xs-only {
  .header .navbar-brand {
    margin-left: 10px;
    margin-right: 0;
    margin-top: 0;
  }
}

/* Mobile menu referral link bottom bar
 * ========================================================================== */

// Mobile menu referral link row divider
.navbar-collapse li.divider {
  background-color: #e5e5e5;
  height: 1px;
}

// Mobile menu referral link row right-chevron
.menu-in-right {
  line-height: 40px;
}

/* Mobile menu promote your profile link bottom bar
 * ========================================================================== */

 .promo-container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 2px;
}

.promo-icon {
  width: 24px;
  height: 24px;
  fill: $color-neutral-800;

  &:hover,
  &:focus {
    fill: $color-neutral-900;
  }
}

.promo-text {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  color: $color-neutral-700;

  &:hover,
  &:focus {
    color: $color-neutral-900;
  }
}

.promo-title {
  font-size: 16px;
  padding-bottom: 4px;
  color: $color-neutral-900;
}

.promo-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  fill: $color-neutral-800;

  &:hover,
  &:focus {
    fill: $color-neutral-900;
  }
}



/* Page Header
 * ========================================================================== */

.rover-page-header {
  @include rover-header-group($bottom-spacing: 6);
}

/* Our Services Header Dropdown
  * ========================================================================== */
.our-services {
  &--list,
  &--dropdown {
    ul {
      margin-bottom: 0;
      padding: 10px;
    }

    ul,
    li {
      list-style: none;
    }
  }

  // Mobile List Dropdown
  &--list {
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease-in-out;

    // Toggle Services Dropdown
    .show-all-services & {
      max-height: 400px;
    }

    // List Item
    .our-services-item {
      text-align: left;

      &:first-child {
        @include xs-only {
          border-top: 1px solid $gray-1;
        }
      }
      &:last-child {
        @include xs-only {
          border-bottom: 1px solid $gray-1;
        }
      }

      // Item Anchor
      &-link {
        color: $color-interactive-text-link-secondary-base;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
          text-decoration: underline;
          color: $color-interactive-text-link-secondary-pressed;
        }

        // List Item Anchor Icon
        &__icon {
          font-size: 15px;
          color: rgb(98, 104, 110);
        }
      }
    }
  }

  // Desktop Dropdown
  &--dropdown {
    display: block;
    visibility: hidden;
    background-color: $white;
    position: absolute;
    z-index: -1;
    width: 100%;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;

    // Toggle Services Dropdown
    .show-all-services & {
      z-index: 3;
    }

    // List Item
    .our-services-item {
      // Item Anchor
      &-link {
        display: block;
        color: $color-interactive-text-link-secondary-base;
        text-decoration: none;
        font-size: 14px;

        &:hover,
        &:active,
        &:focus {
          text-decoration: underline;
          color: $color-interactive-text-link-secondary-pressed;
        }

        // List Item Anchor Icon
        &__icon {
          display: block;
          color: $color-brand-rover;
          font-size: 30px;
        }
      }
    }
  } // end --dropdown
}

.language-selector {
  > li > a {
    line-height: 22px;
    padding-bottom: 15px;
  }

  .dropdown-toggle:focus {
    /* Override removal of outline in Bootstrap .dropdown-toggle */
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
}

.nav.language-selector.visible-xs {
  border: none;
  list-style-type: none;
  padding: 0;
  margin: 0;

  > li > a {
    color: $text-color;

    padding: 10px;
    z-index: 1001;
  }

  > li > a:hover {
    text-decoration: none;
  }

  .dropdown-menu {
    box-shadow: none !important;
    border-radius: 0;
    list-style-type: none;
    z-index: 1000;
    margin-top: -1px !important;
  }

  .open > a {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: none;
    padding: $nav-link-padding - 1;
    padding-bottom: $nav-link-padding;
  }
}

@media (max-width: 374px) {
  // shrink logo to fit language selector in small mobile screens, if enabled
  .header-with-language-selector .navbar-brand {
    font-size: 20px;
    line-height: 38px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .anonymous-header li a {
    padding-left: 8px;
    padding-right: 8px;
  }
  .language-selector .dropdown-toggle .caret {
    float: right;
    position: relative;
    top: -12px;
    right: -12px;
  }
  .language-selector .dropdown.open .caret {
    display: none;
  }
}
